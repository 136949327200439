import { useEffect } from 'react';

function handleSigninSuccess(res) {
  /*
    offer renamed response keys to names that match use
  */
  const basicProfile = res.getBasicProfile();
  const authResponse = res.getAuthResponse(true);
  res.googleId = basicProfile.getId();
  res.tokenObj = authResponse;
  res.tokenId = authResponse.id_token;
  res.accessToken = authResponse.access_token;
  res.profileObj = {
    googleId: basicProfile.getId(),
    imageUrl: basicProfile.getImageUrl(),
    email: basicProfile.getEmail(),
    name: basicProfile.getName(),
    givenName: basicProfile.getGivenName(),
    familyName: basicProfile.getFamilyName(),
  };
  return res;
}

const useGoogleLogin = ({
  onSuccess = () => {},
  onAutoLoadFinished = () => {},
  onFailure = () => {},
  onRequest = () => {},
  onScriptLoadFailure,
  clientId,
  cookiePolicy,
  loginHint,
  hostedDomain,
  isSignedIn,
  autoLoad,
  fetchBasicProfile,
  redirectUri,
  discoveryDocs,
  uxMode,
  scope,
  accessType,
  responseType,
  prompt,
}) => {
  function signIn(e) {
    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }
    const GoogleAuth = window.gapi.auth2.getAuthInstance();
    const options = {
      prompt,
    };
    onRequest();
    if (responseType === 'code') {
      GoogleAuth.grantOfflineAccess(options).then(
        (res) => onSuccess(res),
        (err) => onFailure(err),
      );
    } else {
      GoogleAuth.signIn(options).then(
        (res) => onSuccess(handleSigninSuccess(res)),
        (err) => onFailure(err),
      );
    }
  }

  useEffect(() => {
    const params = {
      client_id: clientId,
      cookie_policy: cookiePolicy,
      login_hint: loginHint,
      hosted_domain: hostedDomain,
      fetch_basic_profile: fetchBasicProfile,
      discoveryDocs,
      ux_mode: uxMode,
      redirect_uri: redirectUri,
      scope,
      access_type: accessType,
    };

    if (responseType === 'code') {
      params.access_type = 'offline';
    }
    const failure = onScriptLoadFailure || onFailure;
    const GoogleAuth = window.gapi.auth2.getAuthInstance();
    if (!GoogleAuth) {
      window.gapi.auth2.init(params).then(
        (res) => {
          const signedIn = isSignedIn && res.isSignedIn.get();
          onAutoLoadFinished(signedIn);
          if (signedIn) {
            handleSigninSuccess(res.currentUser.get());
          }
        },
        (err) => {
          onAutoLoadFinished(false);
          failure(err);
        },
      );
    } else {
      GoogleAuth.then(
        () => {
          if (isSignedIn && GoogleAuth.isSignedIn.get()) {
            onAutoLoadFinished(true);
            onSuccess(handleSigninSuccess(GoogleAuth.currentUser.get()));
          } else {
            onAutoLoadFinished(false);
          }
        },
        (err) => {
          failure(err);
        },
      );
    }
    if (autoLoad) {
      signIn();
    }
  }, []);

  return { signIn };
};

export default useGoogleLogin;
