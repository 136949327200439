import Cookies from 'js-cookie';
import { get } from 'lodash';
import appConfig from 'app/Config';
import { toMoment } from 'utils/dates';

const GUSER_INFO = 'gslides_user';
const GUSER_TOKEN = 'gslides_token';

const COOKIE_DOMAIN = window.location.hostname;

const env = appConfig.get('env');
const BASE_COOKIE_CONFIG =
  env === 'qa' || env === 'prod'
    ? { sameSite: 'none', secure: true }
    : { sameSite: 'lax' };

class GSlidesSession {
  /**
   * getDomainCookie
   * @param {string} name
   */
  getDomainCookie = (name) => {
    const value = Cookies.getJSON(name);
    return value || null;
  };

  setGUser = (guser) => {
    Cookies.set(
      GUSER_INFO,
      { guser },
      { ...BASE_COOKIE_CONFIG, domain: COOKIE_DOMAIN },
    );
  };

  setGUserToken = (token) => {
    const expireAt = toMoment();
    const expiresIn = token.expires_in;
    expireAt.add(expiresIn, 'seconds');
    const guserToken = {
      token_type: token.token_type,
      access_token: token.access_token,
      id_token: token.id_token,
      expires_at: expireAt,
    };
    Cookies.set(
      GUSER_TOKEN,
      { guserToken },
      { ...BASE_COOKIE_CONFIG, domain: COOKIE_DOMAIN },
    );
  };

  hasExpired = () => {
    const expireAt = get(
      this.getDomainCookie(GUSER_TOKEN),
      'guserToken.expires_at',
    );
    const currentDate = toMoment();
    return currentDate.isSameOrAfter(expireAt);
  };

  getAccessToken = () => {
    const token = get(
      this.getDomainCookie(GUSER_TOKEN),
      'guserToken.access_token',
    );
    return token;
  };

  getGName = () => {
    const name = get(this.getDomainCookie(GUSER_INFO), 'guser.name', '');
    return name;
  };

  getGUser = () => {
    const user = get(this.getDomainCookie(GUSER_INFO), 'guser', {});
    return user;
  };

  isSignedIn = () => {
    return (
      get(this.getDomainCookie(GUSER_INFO), 'guser') !== undefined &&
      !this.hasExpired()
    );
  };

  clearGCookies = () => {
    Cookies.remove(GUSER_INFO, { domain: COOKIE_DOMAIN });
    Cookies.remove(GUSER_TOKEN, { domain: COOKIE_DOMAIN });
  };
}

const gslidesSession = new GSlidesSession();

export default gslidesSession;
