import React from 'react';
import metricsService from 'app/Services/MetricsService';
import SummaryCard from 'common/SummaryCard';
import useCardState from 'common/SummaryCard/useCardState';

function TopShares() {
  const state = useCardState(metricsService.topShares);

  return (
    <SummaryCard
      data={state.metrics.map((item) => ({
        id: item.demo_id,
        label: item.demo_name,
        value: item.shares,
      }))}
      error={state.error}
      isLoading={state.isLoading}
      onSelect={state.loadMetrics}
      title="Top shares"
    />
  );
}

export default TopShares;
