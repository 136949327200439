import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import Shell from 'app/Shells/Management';
import GSlidesConfig from 'app/Forms/GSlidesConfig';
import GImportTemplate from 'app/Forms/GImportTemplate';
import useSearchState from 'utils/useSearchState';
import { useRequest } from 'utils/Request';
import AuthModal from 'utils/Google/AuthModal';
import useModalState from 'utils/useModalState';
import gslidesSession from 'utils/GSlidesSession';

import ManagementTable from 'app/Views/ManagementTable';

import gImportTemplate from 'app/Services/GSlides/GImportTemplate';
import gSettingsService from 'app/Services/GSlides/GSettingsCRUDService';
import gTemplatesService from 'app/Services/GSlides/GTemplatesCRUDService';
import { useToasts } from 'common/ActionToast';
import IconButton from 'common/Buttons/Icon';
import { IUpload, SettingsI } from 'common/icons';
import SlidesGrouping from 'app/Views/SlidesGrouping';
import SlidesNavigation from 'app/Views/SlidesNavigation';
import { tRows } from './table';

import './styles.scss';
import Texts from './texts';

function GSlidesPage() {
  const searchState = useSearchState(
    gTemplatesService.search,
    ['q'],
    undefined,
    {
      sort: [{ field: 'presentation_name', order: 'asc' }],
    },
  );
  const [settings, setSettings] = useState({});

  const authActionModal = useModalState();
  const settingsModal = useModalState({ initialShow: true });
  const importModal = useModalState();
  const groupingModal = useModalState();
  const generateModal = useModalState();
  const toasts = useToasts();
  const useImportTemplate = useRequest(gImportTemplate, {
    onSuccess: (data) => {
      importModal.close();
      toasts.onAdd(
        Math.random(),
        Texts.importToastTitle,
        Texts.importToastSuccess(data.name),
      );
      searchState.reload();
    },
  });
  const useRemoveTemplate = useRequest(gTemplatesService.delete, {
    onSuccess: () => {
      importModal.close();
      toasts.onAdd(
        Math.random(),
        Texts.removeToastTitle,
        Texts.removeToastSuccess,
      );
      searchState.reload();
    },
  });
  const useGSettings = useRequest(gSettingsService.save, {
    onSuccess: (data) => {
      setSettings(data);
      settingsModal.close();
      toasts.onAdd(
        'SettingsSuccess',
        Texts.settingsToastTitle,
        Texts.settingsToastSuccess,
      );
    },
    onError: () => {
      settingsModal.close();
      toasts.onAdd(
        'SettingsError',
        Texts.settingsToastTitle,
        Texts.settingsToastError,
      );
    },
  });
  const useLoadGSettings = useRequest(gSettingsService.load, {
    onSuccess: (responSettings) => {
      setSettings(responSettings);
    },
  });

  useEffect(() => {
    useLoadGSettings.start();
  }, []);

  const viewActions = useMemo(
    () => (
      <div className="d-flex">
        {[
          <IconButton
            key="import"
            disabled={!settings}
            message="Import"
            className="py-1 px-2 bg-primary rounded"
            onClick={importModal.open}
            Icon={IUpload}
          />,
          <IconButton
            key="settings"
            disabled={useLoadGSettings.inProcess}
            message="Settings"
            className="py-1 px-2 ml-1 bg-primary rounded"
            onClick={settingsModal.open}
            Icon={SettingsI}
          />,
        ]}
      </div>
    ),
    [],
  );

  const rowActions = useMemo(
    () => [
      {
        handler: generateModal.open,
        label: 'Generate Presentation',
      },
      {
        handler: groupingModal.open,
        label: 'Slide Groups',
      },
      {
        handler: (data) => {
          if (gslidesSession.isSignedIn()) {
            useImportTemplate.start({
              template_file: {
                id: data.presentation_id,
                templateId: data.id,
                name: data.presentation_name,
              },
            });
          } else {
            authActionModal.open();
          }
        },
        label: 'Refresh Template',
      },
      {
        handler: (data) => {
          useRemoveTemplate.start(data.id);
        },
        label: 'Remove Template',
      },
    ],
    [],
  );

  const handleCellClicked = (change) => {
    console.log('handleCellClicked', change);
  };

  const handleSettingsSubmit = (form, bag) => {
    useGSettings.start(form, bag);
  };

  const handleImportTemplate = (form, bag) => {
    useImportTemplate.start(form, bag);
  };

  return (
    <Shell page="GSlides">
      {toasts.elements}
      {authActionModal.show && <AuthModal onCancel={authActionModal.close} />}
      {importModal.show && (
        <GImportTemplate
          folderId={get(settings, 'templates_folder.id')}
          onCancel={importModal.close}
          onSubmit={handleImportTemplate}
          show={importModal.show}
        />
      )}
      {settingsModal.show && (
        <GSlidesConfig
          onCancel={settingsModal.close}
          onSubmit={handleSettingsSubmit}
          show={settingsModal.show}
          values={settings}
        />
      )}
      {generateModal.show && (
        <SlidesNavigation
          onCancel={generateModal.close}
          onSuccess={generateModal.close}
          templateId={generateModal.value.id}
          show={generateModal.show}
        />
      )}
      {groupingModal.show && (
        <SlidesGrouping
          onCancel={groupingModal.close}
          onSuccess={groupingModal.close}
          templateId={groupingModal.value.id}
          show={groupingModal.show}
        />
      )}
      {settings && (
        <ManagementTable
          viewActions={viewActions}
          rows={tRows}
          rowActions={rowActions}
          onCellClicked={handleCellClicked}
          searchState={searchState}
        />
      )}
    </Shell>
  );
}

export default GSlidesPage;
