import React, { useEffect, useState } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';

import { useRequest } from 'utils/Request';
import GeneratePresentationService from 'app/Services/GSlides/GeneratePresentationService';
import LoadingSpinner from 'common/LoadingSpinner';

function PresentationGeneration({ presentationData }) {
  const [generatedPresentation, setGeneratedPresentation] = useState(undefined);

  const generatePresentationRequest = useRequest(GeneratePresentationService, {
    onSuccess: (newPresentation) => {
      setGeneratedPresentation(newPresentation);
    },
    onError: () => {},
  });

  useEffect(() => {
    generatePresentationRequest.start(presentationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!generatePresentationRequest.inProcess &&
        generatePresentationRequest.error && (
          <div>Error generating the presentation</div>
        )}

      {generatePresentationRequest.inProcess &&
        !generatePresentationRequest.error && (
          <LoadingSpinner message="Generating" />
        )}

      {!generatePresentationRequest.inProcess &&
        !generatePresentationRequest.error &&
        generatedPresentation && (
          <div>
            <div>
              <span className="mr-2">Presentation Name:</span>
              <span>{generatedPresentation.name}</span>
            </div>
            <div>
              <span className="mr-2">Presentation URL:</span>
              <span>
                <a
                  href={generatedPresentation.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </span>
            </div>
          </div>
        )}
    </div>
  );
}

PresentationGeneration.propTypes = {
  presentationData: shape({
    groups: arrayOf(
      shape({
        id: number.isRequired,
        section_name: string.isRequired,
        slide_list: arrayOf(string).isRequired,
      }),
    ),
    groupsOrder: arrayOf(string).isRequired,
    name: string.isRequired,
    uploadedValues: shape({}).isRequired,
    selectedSlides: shape({}).isRequired,
  }).isRequired,
};

PresentationGeneration.defaultProps = {};

export default PresentationGeneration;
