import { shape, string } from 'prop-types';
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function PageviewsAreaChart({ data }) {
  return (
    <ResponsiveContainer width="100%" height={220}>
      <AreaChart
        data={data.pageviews}
        margin={{ top: 5, right: 5, left: 0, bottom: 0 }}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area
          dot
          dataKey="total_pageviews"
          stroke="#54d3a2"
          fill="#cbf2e3"
          name={
            data.interval === 'DAY'
              ? 'Visits per day'
              : 'Visits per calendar week'
          }
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

PageviewsAreaChart.propTypes = {
  data: shape({
    interval: string,
    pageviews: string,
  }).isRequired,
};

export default PageviewsAreaChart;
