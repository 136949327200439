import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { get } from 'lodash';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Texts from './texts';
import './styles.scss';

const DEFAULT_SOURCE = 'VIDEO';

function EmbedForm({
  errors,
  value,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
}) {
  const [activeTab, setActiveTab] = useState(
    get(value, 'embedMetadata.source', DEFAULT_SOURCE),
  );

  const switchTab = (newTab) => {
    setActiveTab(newTab);
    setFieldValue('embedMetadata.source', newTab);
    setFieldValue('embedMetadata.props', {});
  };

  return (
    <div className="embed-form">
      <Tabs activeKey={activeTab} onSelect={switchTab}>
        <Tab eventKey="VIDEO" title="Video" className="px-4 pt-3 pb-3">
          <Form.Group as={Row}>
            <Form.Label column sm={2} htmlFor="url" className="pl-0 url-label">
              {Texts.url}
            </Form.Label>
            <Col sm={10} className="p-0">
              <Form.Control
                name="embedMetadata.url"
                type="text"
                value={get(value, 'embedMetadata.url')}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  get(touched, 'embedMetadata.url') &&
                  get(errors, 'embedMetadata.url')
                }
              />
              {get(touched, 'embedMetadata.url') &&
                get(errors, 'embedMetadata.url') && (
                  <Form.Control.Feedback type="invalid">
                    {get(errors, 'embedMetadata.url')}
                  </Form.Control.Feedback>
                )}
            </Col>
          </Form.Group>
          <Row>
            <div className="d-flex justify-content-between">
              <Form.Check
                className="d-flex flex-row-reverse props-checkbox"
                name="embedMetadata.props.autoplay"
                type="checkbox"
                checked={get(value, 'embedMetadata.props.autoplay', false)}
                value={get(value, 'embedMetadata.props.autoplay', false)}
                label={Texts.autoplay}
                onChange={handleChange}
              />
              <Form.Check
                className="d-flex flex-row-reverse props-checkbox"
                name="embedMetadata.props.loop"
                type="checkbox"
                checked={get(value, 'embedMetadata.props.loop', false)}
                value={get(value, 'embedMetadata.props.loop', false)}
                label={Texts.loop}
                onChange={handleChange}
              />
              <Form.Check
                className="d-flex flex-row-reverse props-checkbox"
                name="embedMetadata.props.showControls"
                type="checkbox"
                checked={get(value, 'embedMetadata.props.showControls', false)}
                value={get(value, 'embedMetadata.props.showControls', false)}
                label={Texts.showControls}
                onChange={handleChange}
              />
              <Form.Check
                className="d-flex flex-row-reverse props-checkbox"
                name="embedMetadata.props.muted"
                type="checkbox"
                checked={get(value, 'embedMetadata.props.muted', false)}
                value={get(value, 'embedMetadata.props.muted', false)}
                label={Texts.muted}
                onChange={handleChange}
              />
            </div>
          </Row>
        </Tab>
        <Tab eventKey="EMBED" title="Embed" className="px-4 pt-3 pb-3">
          <Form.Group as={Row}>
            <Form.Label column sm={2} htmlFor="url" className="pl-0 url-label">
              {Texts.url}
            </Form.Label>
            <Col sm={10} className="p-0">
              <Form.Control
                name="embedMetadata.url"
                type="text"
                value={get(value, 'embedMetadata.url')}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  get(touched, 'embedMetadata.url') &&
                  get(errors, 'embedMetadata.url')
                }
              />
              {get(touched, 'embedMetadata.url') &&
                get(errors, 'embedMetadata.url') && (
                  <Form.Control.Feedback type="invalid">
                    {get(errors, 'embedMetadata.url')}
                  </Form.Control.Feedback>
                )}
            </Col>
          </Form.Group>
        </Tab>
      </Tabs>
    </div>
  );
}

EmbedForm.propTypes = {
  errors: shape({}).isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  setFieldValue: func.isRequired,
  touched: shape({}).isRequired,
  value: shape({
    embedMetadata: shape({}),
  }),
};

EmbedForm.defaultProps = {
  value: {
    embedMetadata: {
      source: undefined,
      url: undefined,
      props: {},
    },
  },
};

export default EmbedForm;
