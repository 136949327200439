import {
  CardImage,
  ChevronRight,
  ChevronDown,
  CodeSlash,
  ClipboardData,
  Gear,
  GearFill,
  FileEarmarkArrowDown,
  FileEarmarkArrowUp,
  Layers,
  LayersFill,
  TrashFill,
  Tv,
  TvFill,
  PencilFill,
  Plus,
  ArrowLeft,
  PencilSquare,
  FileEarmarkEasel,
  FileEarmarkEaselFill,
  Upload,
} from 'react-bootstrap-icons';

export const ChevronRightI = ChevronRight;
export const ChevronDownI = ChevronDown;
export const InteractiveI = CodeSlash;
export const LibraryI = Layers;
export const LibraryIActive = LayersFill;
export const ReportI = ClipboardData;
export const SettingsI = Gear;
export const SettingsIActive = GearFill;
export const DashI = Tv;
export const DashIActive = TvFill;
export const AddI = Plus;
export const GoBackI = ArrowLeft;
export const EditI = PencilSquare;
export const GSlidesI = FileEarmarkEasel;
export const GSlidesIActive = FileEarmarkEaselFill;
export const IUpload = Upload;
export const CardImageI = CardImage;
export const FileEarmarkArrowDownI = FileEarmarkArrowDown;
export const FileEarmarkArrowUpI = FileEarmarkArrowUp;
export const PencilFillI = PencilFill;
export const TrashFillI = TrashFill;
