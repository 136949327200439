/* eslint-disable no-unused-vars */
import { get } from 'lodash';
import gclient from 'utils/Google/GoogleClient';

const GetFolderPresentations = (folderId) => async (fileName, handlers) => {
  try {
    const res = await gclient.drive().files.list({
      q: `mimeType = 'application/vnd.google-apps.presentation' and parents in '${folderId}' and name  contains '${fileName}'`,
      fields: 'files (name, id)',
    });
    const files = get(res, 'result.files', []).map((item) => ({
      label: item.name,
      value: item,
    }));
    if (!get(handlers, 'onSuccess')) return files;
    return handlers.onSuccess(files);
  } catch (error) {
    if (!get(handlers, 'onError')) return error;
    return handlers.onError(error);
  }
};

export default GetFolderPresentations;
