import { arrayOf, func, string, shape, element } from 'prop-types';

const propTypes = {
  viewActions: element,
  onCellClicked: func,
  rows: arrayOf(
    shape({
      headerComponent: func,
      headerLabel: string,
      headerValue: string,
      key: string,
      cellComponent: func,
    }),
  ),
  rowActions: arrayOf(
    shape({
      handler: func,
      label: string,
    }),
  ),
  searchState: shape({}),
  searchPlaceholder: string.isRequired,
};

export default propTypes;
