import { useEffect } from 'react';
import reportsService from 'app/Services/ReportsService';
import useSearchState from 'utils/useSearchState';

function usePublishedDemosState(clientId, demoId, startDate, endDate) {
  const search = useSearchState(
    reportsService.publishedDemosReport,
    [],
    {
      clientId,
      demoId,
      start_date: startDate,
      end_date: endDate,
    },
    {
      sort: [{ field: 'pageviews', order: 'desc' }],
    },
  );

  useEffect(() => {
    search.changeFilter({
      client: clientId,
      demo: demoId,
      start_date: startDate,
      end_date: endDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, demoId, startDate, endDate]);

  return {
    data: search.query.result.data, // lodash
    query: search.query,
    reload: search.reload,
    search: search.change,
    changePage: search.changePage,
    changeSort: search.changeSort,
    changeLimit: search.changeLimit,
  };
}

export default usePublishedDemosState;
