/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from 'react';
import Shell from 'app/Shells/Management';
import Card from 'react-bootstrap/Card';
import fromQS from 'utils/fromQS';
import { useHistory, useParams } from 'react-router-dom';
import { ThreeDots as ThreeDotsIcon } from 'react-bootstrap-icons';
import { get } from 'lodash';
import routerMap from 'app/RouterMap';
import useSearchState from 'utils/useSearchState';
import useModalState from 'utils/useModalState';
import { to_MMDDYYYYHHMMA } from 'utils/dates';
import DataTable from 'common/DataTable';
import IconButton from 'common/Buttons/Icon';
import SortableHeader from 'common/DataTable/SortableHeader';
import SearchInput from 'common/Inputs/Search';
import { useToasts } from 'common/ActionToast';
import FloatingMenu, {
  FloatinMenuOption,
  FloatingMenuState,
} from 'common/FloatingMenu';
import PrimitiveButton from 'common/Buttons/Primitive';
import Button from 'common/Buttons/Button';

import { AddI, EditI, GoBackI } from 'common/icons';
import { useRequest } from 'utils/Request';
import { canBeArchived, canBeUnarchived } from 'utils/business/demo';
import interactiveService from 'app/Services/InteractiveCRUDService';
import publishService from 'app/Services/PublishedInteractiveCRUDService';
import PublishInteractiveForm from 'app/Forms/PublishInteractive';
import Texts from './texts';
import './styles.scss';

function PublishedInteractivePage() {
  const history = useHistory();
  const { id: interactiveId } = useParams();
  const publicationModal = useModalState();
  const toasts = useToasts();
  const search = useSearchState(
    interactiveService.searchPublications(interactiveId),
    ['q'],
    undefined,
    {
      sort: [{ field: 'clients.name', order: 'asc' }],
    },
  );
  const addService = useRequest(publishService.create, {
    onSuccess: (item) => {
      publicationModal.close();
      search.reload();
      toasts.onAdd(item.id, Texts.createdTitle, Texts.createdDescription(item));
    },
    onError: () => {},
  });
  const archiveService = useRequest(publishService.archive, {
    onSuccess: () => {
      search.reload();
    },
    onError: () => {},
  });
  const unarchiveService = useRequest(publishService.unarchive, {
    onSuccess: () => {
      search.reload();
    },
    onError: () => {},
  });

  const handleRowClick = (published) => {
    history.push(routerMap.interactiveApp.getPath(published.id));
  };

  const handleEditPublication = (id) => {
    history.push(routerMap.interactiveAppAdmin.getPath(id));
  };

  const handlePublish = (form, formikBag) => {
    const publication = {
      id: form.id,
      client: form.client,
      interactive_id: interactiveId,
    };
    addService.start(publication, formikBag);
  };

  const handleEditTemplate = () => {
    history.push(routerMap.interactiveAppAdmin.getPath(interactiveId, true));
  };

  const handleGoBack = () => {
    history.push(routerMap.interactive.getPath());
  };

  return (
    <Shell
      page={
        <div className="d-flex">
          <PrimitiveButton onClick={handleGoBack}>
            <GoBackI />
          </PrimitiveButton>
          <h4 className="ml-2 mb-0">{fromQS('name')}</h4>
        </div>
      }
    >
      <div>
        {toasts.elements}
        {publicationModal.show && (
          <PublishInteractiveForm
            onCancel={publicationModal.close}
            onSuccess={publicationModal.close}
            onSubmit={handlePublish}
            show={publicationModal.show}
            interactivePublished={publicationModal.value}
            interactiveName={fromQS('name')}
          />
        )}
        <div id="interactive_published">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <SearchInput
                placeholder="Search by Client"
                name="publicationSearch"
                onChange={search.change}
                value={search.query.qs}
              />
            </div>
            <div className="d-flex">
              <div className="mr-3 d-flex">
                <span className="mr-1">Template</span>
                <IconButton onClick={handleEditTemplate} Icon={EditI} />
              </div>
              <IconButton onClick={publicationModal.open} Icon={AddI} />
            </div>
          </div>
          <div>
            <DataTable
              onLimitChange={search.changeLimit}
              onPageChange={search.changePage}
              onSortChange={search.changeSort}
              onReload={search.reload}
              total={get(search, 'query.result.total', 0)}
              page={search.query.page}
              limit={search.query.limit}
              isFetching={search.query.isLoading}
              hasError={search.query.error}
            >
              <thead>
                <tr>
                  <SortableHeader
                    onChange={search.changeSort}
                    value="clients.name"
                    current={search.query.sort}
                  >
                    Client
                  </SortableHeader>
                  <SortableHeader
                    onChange={search.changeSort}
                    value="users.username"
                    current={search.query.sort}
                  >
                    Added By
                  </SortableHeader>
                  <SortableHeader
                    onChange={search.changeSort}
                    value="created_at"
                    current={search.query.sort}
                  >
                    Date Added
                  </SortableHeader>
                  <SortableHeader
                    onChange={search.changeSort}
                    value="updated_at"
                    current={search.query.sort}
                  >
                    Date Updated
                  </SortableHeader>
                </tr>
              </thead>
              {!search.query.isLoading && (
                <tbody>
                  {get(search, 'latestResponse.data').map((item) => (
                    <tr key={item.id}>
                      <td
                        className="bold link"
                        role="presentation"
                        onClick={() => handleRowClick(item)}
                      >
                        {item.client.name}
                      </td>
                      <td>{item.user.username}</td>
                      <td>{to_MMDDYYYYHHMMA(item.created_at)}</td>
                      <td>{to_MMDDYYYYHHMMA(item.updated_at)}</td>
                      <td>
                        <FloatingMenuState>
                          {({ show, open }) => (
                            <>
                              <ThreeDotsIcon
                                onClick={open}
                                className="cursor-pointer"
                              />
                              {show && (
                                <FloatingMenu id="interactive_publication-floating-menu">
                                  <Card className="py-1">
                                    <FloatinMenuOption
                                      onClick={handleEditPublication}
                                      value={item.id}
                                    >
                                      Edit
                                    </FloatinMenuOption>
                                    {canBeArchived(item) && (
                                      <FloatinMenuOption
                                        onClick={archiveService.start}
                                        value={item.id}
                                      >
                                        Archive
                                      </FloatinMenuOption>
                                    )}
                                    {canBeUnarchived(item) && (
                                      <FloatinMenuOption
                                        onClick={unarchiveService.start}
                                        value={item.id}
                                      >
                                        Unarchive
                                      </FloatinMenuOption>
                                    )}
                                  </Card>
                                </FloatingMenu>
                              )}
                            </>
                          )}
                        </FloatingMenuState>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </DataTable>
          </div>
        </div>
      </div>
    </Shell>
  );
}

export default PublishedInteractivePage;
