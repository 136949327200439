import React from 'react';
import { string, node, oneOfType } from 'prop-types';
import { Card } from 'react-bootstrap';
import { ChevronDownI, ChevronRightI } from 'common/icons';
import useToggle from 'utils/useToggleState';
import Expandable from 'common/Animations/Expandable';

const ExpandableGroupSlides = ({ name, children, className }) => {
  const toggle = useToggle(true);
  const Icon = toggle.isActive ? ChevronDownI : ChevronRightI;
  return (
    <Expandable
      expanded={toggle.isActive}
      onToggle={toggle.onToggle}
      trigger={
        <Card.Title className="mb-0 text-capitalize cursor-pointer d-flex align-items-center">
          {name} <Icon className="ml-3" />
        </Card.Title>
      }
      content={<div className={className}>{children}</div>}
    />
  );
};

ExpandableGroupSlides.propTypes = {
  children: node.isRequired,
  className: string,
  name: oneOfType([string, node]).isRequired,
};

ExpandableGroupSlides.defaultProps = {
  className: null,
};

export default ExpandableGroupSlides;
