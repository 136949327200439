import React, { useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { find, get } from 'lodash';
import CreatableSelect from 'react-select/creatable';

const MAX_ITEMS = 6;

function MultiCreatableSelect({
  allowMultipleOptions,
  isClearable,
  isDisabled,
  labelField,
  maxItems,
  name,
  onChange,
  options,
  placeholder,
  setFieldValue,
  value,
  valueField,
  ...rest
}) {
  const [values, setValues] = useState(value);

  const getOptionValue = (option) => option[valueField];

  const getOptionLabel = (option) => option[labelField];

  const mapOptionData = (inputValue, optionLabel) => ({
    [valueField]: inputValue,
    [labelField]: optionLabel,
    __isNew__: true,
  });

  /**
   * Sets the final value in the form of:
   * { id: undefined, [labelField]: valueAdded }
   * The id is set to undefined to indicate that it is a new value
   * @param {option} selOption
   */
  const normalizeOption = (selOption) => {
    const normalizedOption = selOption
      ? {
          [valueField]: get(selOption, '__isNew__')
            ? undefined
            : selOption[valueField],
          [labelField]: selOption[labelField],
        }
      : selOption;
    return normalizedOption;
  };

  const normalizeMultipleOptions = (selOptions) => {
    const normalizedOptions = (selOptions || []).map((opt) =>
      normalizeOption(opt),
    );
    return normalizedOptions;
  };

  const setFormOptionValue = (option) => {
    if (allowMultipleOptions) {
      setFieldValue(name, normalizeMultipleOptions(option));
    } else {
      setFieldValue(name, normalizeOption(option));
    }
  };

  const handleOnChange = (option, action) => {
    if (onChange) {
      onChange(option, action);
    } else {
      setFormOptionValue(option);
    }
    setValues(option);
  };

  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    if (inputValue === '' || get(selectValue, 'length') === maxItems) {
      return false;
    }

    const optionValueLowerCase = (inputValue || '').toLowerCase();
    const allOptions = [...selectValue, ...selectOptions];

    const exists = find(allOptions, (option) => {
      return get(option, labelField, '').toLowerCase() === optionValueLowerCase;
    });

    return !exists;
  };

  const atMaxCapacity = () => {
    return get(value, 'length') === maxItems;
  };

  const getNoOptionMessage = () => {
    return atMaxCapacity() ? `Only ${maxItems} items allowed` : 'No Options';
  };

  return (
    <CreatableSelect
      getNewOptionData={mapOptionData}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      noOptionsMessage={getNoOptionMessage}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={allowMultipleOptions}
      isValidNewOption={isValidNewOption}
      name={name}
      onChange={handleOnChange}
      options={atMaxCapacity() ? [] : options}
      placeholder={placeholder}
      value={values}
      {...rest}
    />
  );
}

MultiCreatableSelect.propTypes = {
  allowMultipleOptions: bool,
  isClearable: bool,
  isDisabled: bool,
  labelField: string.isRequired,
  maxItems: number,
  name: string.isRequired,
  onChange: func,
  options: arrayOf(shape({})).isRequired,
  placeholder: string,
  setFieldValue: func.isRequired,
  value: shape({}),
  valueField: string.isRequired,
};

MultiCreatableSelect.defaultProps = {
  allowMultipleOptions: false,
  maxItems: MAX_ITEMS,
  onChange: undefined,
  isClearable: false,
  isDisabled: false,
  placeholder: '',
  value: undefined,
};

export default MultiCreatableSelect;
