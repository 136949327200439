import React from 'react';
import { string } from 'prop-types';

function Header({ children, ...other }) {
  return <th {...other}>{children}</th>;
}

Header.propTypes = {
  children: string.isRequired,
};

export default Header;
