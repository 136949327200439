import React, { useMemo } from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import cx from 'classnames';
import './styles.scss';

const STATE_NEXT_VALUE = {
  off: 'asc',
  asc: 'desc',
  desc: 'asc',
};

function SortableHeader({ children, current, value, onChange }) {
  const currentDirection = useMemo(() => {
    const found = (current || []).find((option) => option.field === value);
    return found ? found.order : 'off';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const handleChange = () => {
    const nextValue = STATE_NEXT_VALUE[currentDirection];
    const newSort =
      nextValue === 'off' ? [] : [{ field: value, order: nextValue }];
    onChange(newSort);
  };
  return (
    <th
      className={cx('sortable-header', currentDirection)}
      onClick={handleChange}
    >
      {children}
      {currentDirection !== 'off' ? `- ${currentDirection.toUpperCase()}` : ''}
    </th>
  );
}

SortableHeader.propTypes = {
  children: string.isRequired,
  current: arrayOf(shape({})).isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

export default SortableHeader;
