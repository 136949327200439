const Texts = {
  interactiveNameLabel: 'Interactive name',
  prototypeLabel: 'Prototype URL',
  editLabel: 'Save',
  cancelLabel: 'Cancel',
  invalidUrl: 'Invalid URL',
  inProcess: 'Saving...',
  createTitle: 'Add Interactive',
  requiredError: (name) => `${name} is required`,
  editTitle: (name) => `Edit ${name}`,
};

export default Texts;
