import React from 'react';
import { bool, node } from 'prop-types';

const ButtonContent = ({ children, icon }) => (
  <span
    style={{
      paddingRight: 10,
      fontWeight: 500,
      paddingLeft: icon ? 0 : 10,
      paddingTop: 10,
      paddingBottom: 10,
    }}
  >
    {children}
  </span>
);

ButtonContent.propTypes = {
  children: node.isRequired,
  icon: bool,
};

ButtonContent.defaultProps = {
  icon: false,
};

export default ButtonContent;
