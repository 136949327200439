import CRUDService from './CRUDService';

class PublishedInteractiveCRUD extends CRUDService {
  constructor() {
    super({
      mapEntityFrom: (data) => data,
      mapListFrom: (data) => data,
      mapTo: (data) => data,
      buildUrl: (suffix) =>
        `api/v1/published_interactives${suffix ? `${suffix}` : ''}`,
    });
  }
}

export default new PublishedInteractiveCRUD();
