const texts = {
  title: 'Edit Guide',
  guideLabel: 'Message',
  header: 'Header',
  footer: 'Footer',
  placementLabel: 'Placement',
  eventLabel: 'Type',
  invalidUrl: 'A valid URL is required',
  requiredError: (name) => `${name} is required`,
};

export default texts;
