import React, { useMemo } from 'react';
import { func, arrayOf, shape, string } from 'prop-types';
import Select from 'react-select';
import { get } from 'lodash';

function SimpleSelect({ name, value, options, onChange, ...rest }) {
  const selectedOption = useMemo(() => {
    if (!value) return undefined;
    return options.find((option) => option.value === value);
  }, [value, options]);

  const handleChange = (change) => {
    const changeValue = get(change, 'value', change);
    if (name) {
      onChange(name, changeValue);
    } else {
      onChange(changeValue);
    }
  };

  return (
    <Select
      {...rest}
      placeholder="Select..."
      options={options}
      value={selectedOption}
      onChange={handleChange}
    />
  );
}

SimpleSelect.propTypes = {
  name: string,
  onChange: func.isRequired,
  options: arrayOf(shape({})),
  value: shape({}),
};

SimpleSelect.defaultProps = {
  name: undefined,
  value: undefined,
  options: [],
};

export default SimpleSelect;
