import { useState, useEffect } from 'react';
import projectsService from 'app/Services/ProjectsService';

function useLoadMarvelScreens(demoId) {
  const [screens, setScreens] = useState([]);

  useEffect(() => {
    projectsService.all(demoId, {
      onSuccess: (res) => {
        setScreens(res);
      },
      onError: () => {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return screens;
}

export default useLoadMarvelScreens;
