import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Card } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';

import Button from 'common/Buttons/Button';
import Slide from 'app/Views/Slide';
import { GeneratePresentationSteps } from 'app/Views/SlidesNavigation/constants';
import SlidesCarousel from '../SlidesCarousel';
import ExpandableGroupSlides from '../ExpandableGroupSlides';

import Texts from './texts';
import './styles.scss';

function SlidesGridEdit({
  groups,
  next,
  slides,
  updateGenData,
  uploadedValues,
}) {
  const [viewMode, setViewMode] = useState('GRID');
  const [formUploadedValues, setFormUploadedValues] = useState(
    uploadedValues || {},
  );
  const [clickedSlideIndex, setClickedSlideIndex] = useState(0);

  const handleValuesUpdate = (valuesToUpdate) => {
    setFormUploadedValues({
      ...uploadedValues,
      ...valuesToUpdate,
    });
  };

  const handleGoBack = () => {
    setViewMode('GRID');
  };

  const saveUpdates = () => {
    updateGenData({
      uploadedValues: {
        ...uploadedValues,
        ...formUploadedValues,
      },
    });
    next(GeneratePresentationSteps.SLIDE_SELECTION);
  };

  const handleSlideClick = (slideId) => {
    // assumes order will be always mainted
    // iterates the keys everytime
    setClickedSlideIndex(Object.keys(slides).findIndex((id) => id === slideId));
    setViewMode('CAROUSEL');
  };

  const goBack = () => {
    next(GeneratePresentationSteps.PLACEHOLDERS_MANAGEMENT);
  };

  useEffect(() => {
    setFormUploadedValues(uploadedValues);
  }, [uploadedValues]);

  return (
    <div id="slides-grid-edit" className="slides-grid-edit-view">
      {viewMode === 'GRID' ? (
        <div>
          <div className="mb-1">
            <h5 className="mb-1">{Texts.title}</h5>
            <span>{Texts.description}</span>
          </div>
          <Container className="px-0 groups">
            {groups.map((group) => (
              <Card
                key={group.id}
                className="my-2 mx-2 px-1 py-1 group-container"
              >
                <Card.Body className="p-0 px-3 py-1 group-body">
                  <ExpandableGroupSlides
                    name={group.section_name}
                    className="pr-1 mt-1"
                  >
                    <Row className="group-slides py-1">
                      {group.slide_list.map((slideId) => (
                        <Col md={3} className="mb-2 px-1" key={slideId}>
                          {slides[slideId] && (
                            <Slide
                              id={slideId}
                              thumbnailUrl={slides[slideId].imageURL}
                              onSlideClick={handleSlideClick}
                            />
                          )}
                        </Col>
                      ))}
                    </Row>
                  </ExpandableGroupSlides>
                </Card.Body>
              </Card>
            ))}
          </Container>

          <div className="d-flex justify-content-end">
            <Button
              size="md"
              variant="secondary"
              onClick={goBack}
              className="mr-2"
            >
              {Texts.return}
            </Button>
            <Button variant="primary" onClick={saveUpdates}>
              {Texts.continue}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-2">
            <Button variant="primary" onClick={handleGoBack}>
              {Texts.return}
            </Button>
          </div>
          <SlidesCarousel
            slides={slides}
            onUpdateValues={handleValuesUpdate}
            openAtIndex={clickedSlideIndex}
            uploadedValues={formUploadedValues}
          />
        </div>
      )}
    </div>
  );
}

SlidesGridEdit.propTypes = {
  groups: arrayOf(
    shape({
      id: number.isRequired,
      section_name: string.isRequired,
      slide_list: arrayOf(string).isRequired,
      selected: bool,
    }),
  ),
  next: func.isRequired,
  slides: shape({}),
  updateGenData: func.isRequired,
  uploadedValues: shape({}),
};

SlidesGridEdit.defaultProps = {
  groups: [],
  slides: {},
  uploadedValues: {},
};

export default SlidesGridEdit;
