import React, { useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';

import routerMap from 'app/RouterMap';
import session from 'utils/Session';

import ProcessingButton from 'common/Buttons/ProcessingButton';
import SubmitErrorMessage from 'common/Message/SubmitError';

import Authenticate from 'app/Services/AuthenticateService';
import Texts from './texts';

const validationSchema = yup.object({
  username: yup.string().required(Texts.usernameError),
  password: yup.string().required(Texts.passwordError),
});

function LoginForm() {
  const history = useHistory();

  useEffect(() => {
    const username = session.getUsername();
    if (username) {
      history.push(routerMap.dash.getPath());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authenticate = (loginFormType, formikBag) =>
    Authenticate(loginFormType, formikBag, {
      onSuccess: () => {
        const lastPage = session.getLastPage();
        if (lastPage && lastPage !== routerMap.login.path) {
          history.push(lastPage);
          session.removeLastPage();
        } else {
          history.push(routerMap.dash.getPath());
        }
      },
    });

  return (
    <Formik
      initialValues={{ username: '', password: '', keepLogin: false }}
      validationSchema={validationSchema}
      onSubmit={authenticate}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        isSubmitting,
      }) => {
        return (
          <Form noValidate>
            <Form.Group>
              <Form.Label htmlFor="username">{Texts.usernameLabel}</Form.Label>
              <Form.Control
                name="username"
                type="text"
                data-testid="usernameId"
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.username && !errors.username}
                isInvalid={touched.username && errors.username}
              />
              {touched.username && errors.username && (
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="password">{Texts.passwordLabel}</Form.Label>
              <Form.Control
                name="password"
                type="password"
                data-testid={Texts.passwordId}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.password && !errors.password}
                isInvalid={touched.password && errors.password}
              />
              {touched.password && errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Check
                name="keepLogin"
                type="checkbox"
                label={Texts.keepMeLabel}
                onChange={handleChange}
              />
            </Form.Group>
            <div className="text-right">
              <ProcessingButton
                isProcessing={isSubmitting}
                variant="primary"
                type="submit"
                processLabel={Texts.authCheckLabel}
                onClick={handleSubmit}
              >
                {Texts.loginButton}
              </ProcessingButton>
            </div>
            {!isSubmitting && errors.invalidCredentials && (
              <SubmitErrorMessage>
                {errors.invalidCredentials}
              </SubmitErrorMessage>
            )}
            {!isSubmitting && errors.internalServerError && (
              <SubmitErrorMessage>
                {errors.internalServerError}
              </SubmitErrorMessage>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default LoginForm;
