import React, { useState, useMemo } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { difference, isEmpty, keyBy, without } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Badge } from 'react-bootstrap';
import { PencilFillI, TrashFillI } from 'common/icons';
import PrimitiveButton from 'common/Buttons/Primitive';
import DroppableSpace from 'app/Views/SlidesGrouping/components/DroppableSpace';
import DraggableSlide from 'app/Views/SlidesGrouping/components/DraggableSlide';

import './styles.scss';

const DEFAULT_GROUP = 'Library';

function PresentationGroups({
  groups,
  onDeleteClick,
  onGroupMoved,
  onSlidesMoved,
  onUpdateClick,
  slides,
}) {
  const groupsById = useMemo(() => keyBy(groups, 'id'), [groups]);
  const [selectedSlideIds, setSelectedSlideIds] = useState([]);

  const selectedSlidesMap = useMemo(() => {
    return selectedSlideIds.reduce((acc, selectedSlideId) => {
      return {
        ...acc,
        [selectedSlideId]: true,
      };
    }, {});
  }, [selectedSlideIds]);

  const unselectAll = () => setSelectedSlideIds([]);

  const isSlideFromCurrentGroup = (groupId, slideId) => {
    if (isEmpty(selectedSlideIds)) {
      return true;
    }

    const selectedSlidesWithoutGroupSlides = difference(
      [...selectedSlideIds, slideId],
      groupsById[groupId].slide_list,
    );

    return isEmpty(selectedSlidesWithoutGroupSlides);
  };

  const handleDragStart = (dragged) => {
    const slideId = dragged.draggableId;
    const selected = selectedSlidesMap[slideId];

    if (!selected) {
      unselectAll();
      setSelectedSlideIds([slideId]);
    }
  };

  const handleSlideDropped = (dropped) => {
    const source = parseInt(dropped.source.droppableId, 10);
    const destination = parseInt(dropped.destination.droppableId, 10);
    const destinationIndex = dropped.destination.index;

    const groupsToUpdate = { ...groupsById };
    const sourceSlideIds = difference(
      groupsToUpdate[source].slide_list,
      selectedSlideIds,
    );

    groupsToUpdate[source].slide_list = sourceSlideIds;
    groupsToUpdate[destination].slide_list.splice(
      destinationIndex,
      0,
      ...selectedSlideIds,
    );

    const updateData = {
      slide_list: [...new Set(selectedSlideIds)],
      src_group_id: source,
      dest_group_id: destination,
      dest_begin_index: destinationIndex,
    };

    onSlidesMoved(updateData);
    unselectAll();
  };

  const onSlideSelect = (slideId) => {
    const newSelectedSlideIds = [];
    const selected = selectedSlidesMap[slideId];

    if (!selected || !isEmpty(selectedSlideIds)) {
      newSelectedSlideIds.push(slideId);
    }

    setSelectedSlideIds(newSelectedSlideIds);
  };

  const toggleInGroup = (groupId, slideId) => {
    const selected = selectedSlidesMap[slideId];

    if (!isSlideFromCurrentGroup(groupId, slideId)) {
      unselectAll();
      return;
    }

    if (!selected) {
      setSelectedSlideIds([...selectedSlideIds, slideId]);
      return;
    }

    const updatedSelectedSlideIds = without(selectedSlideIds, slideId);

    setSelectedSlideIds(updatedSelectedSlideIds);
  };

  const handleGroupDropped = (dropped) => {
    const groupId = parseInt(dropped.draggableId, 10);
    const destinationIndex = dropped.destination.index;
    const groupAtDestination = groups[destinationIndex];

    const updatedGroupPosition = {
      groupId,
      destinationIndex: groupAtDestination.index_order,
    };

    onGroupMoved(updatedGroupPosition);
  };

  const handleDropEnd = (dropped) => {
    if (!dropped?.destination) {
      unselectAll();
      return;
    }

    if (dropped.type === 'slide') {
      handleSlideDropped(dropped);
    } else if (dropped.type === 'group') {
      handleGroupDropped(dropped);
    }
  };

  const toggleSlideIds = (currentGroup, start, end) => {
    const inBetweenSelection = currentGroup.slide_list.slice(start, end + 1);

    const toggledSlideIds = inBetweenSelection.filter((id) => {
      if (selectedSlideIds.includes(id)) {
        return false;
      }
      return true;
    });

    return toggledSlideIds;
  };

  const multiSelectTo = (groupId, slideId) => {
    if (!isSlideFromCurrentGroup(groupId, slideId)) {
      unselectAll();
      return;
    }

    const currentGroup = groupsById[groupId];

    const lastSelected = selectedSlideIds[selectedSlideIds.length - 1];
    const lastIndex = currentGroup.slide_list.indexOf(lastSelected);
    const newIndex = currentGroup.slide_list.indexOf(slideId);

    if (lastIndex === newIndex) {
      return;
    }

    const isSelectingForwards = newIndex > lastIndex;
    const start = isSelectingForwards ? lastIndex : newIndex;
    const end = isSelectingForwards ? newIndex : lastIndex;

    const toggledSlideIds = toggleSlideIds(currentGroup, start, end);
    const updatedSelectedSlideIds = [...selectedSlideIds, ...toggledSlideIds];

    setSelectedSlideIds(updatedSelectedSlideIds);
  };

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDropEnd}>
      <Droppable droppableId="droppable" type="group" direction="horizontal">
        {(provided) => (
          <div ref={provided.innerRef} className="group-body">
            <div className="group-list-container d-flex mr-4">
              {groups.map((group, indexG) => (
                <Draggable
                  key={group.id}
                  draggableId={`${group.id}`}
                  index={indexG}
                >
                  {(providedGroup) => (
                    <div
                      className="slides-group mb-2 mr-4"
                      key={group.id}
                      ref={providedGroup.innerRef}
                      {...providedGroup.draggableProps}
                      {...providedGroup.dragHandleProps}
                    >
                      <div className="d-flex group-header justify-content-between align-items-center p-1">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 mr-2 text-capitalize">
                            {group.section_name}
                          </h5>
                          <Badge variant="info" pill>
                            {group.slide_list.length}
                          </Badge>
                        </div>
                        {group.section_name !== DEFAULT_GROUP && (
                          <div className="d-flex mr-1">
                            <div className="mr-2">
                              <PrimitiveButton
                                onClick={onUpdateClick}
                                value={group}
                              >
                                <PencilFillI className="text-dark" />
                              </PrimitiveButton>
                            </div>
                            <div className="mr-1">
                              <PrimitiveButton
                                onClick={onDeleteClick}
                                value={group}
                              >
                                <TrashFillI className="text-dark" />
                              </PrimitiveButton>
                            </div>
                          </div>
                        )}
                      </div>
                      <DroppableSpace groupId={group.id}>
                        <div key={group.id}>
                          {group.slide_list.map((slideId, index) => (
                            <DraggableSlide
                              key={slideId}
                              groupId={group.id}
                              index={index}
                              isSelected={selectedSlidesMap[slideId]}
                              multiSelectTo={multiSelectTo}
                              onSelect={onSlideSelect}
                              selectedSlidesCount={selectedSlideIds.length}
                              slideId={slideId}
                              thumbnailUrl={slides[slideId].imageURL}
                              toggleInGroup={toggleInGroup}
                            />
                          ))}
                        </div>
                      </DroppableSpace>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

PresentationGroups.propTypes = {
  groups: arrayOf(
    shape({
      id: number.isRequired,
      index_order: number.isRequired,
      section_name: string.isRequired,
      slide_list: arrayOf(string).isRequired,
    }),
  ).isRequired,
  onDeleteClick: func.isRequired,
  onGroupMoved: func.isRequired,
  onSlidesMoved: func.isRequired,
  onUpdateClick: func.isRequired,
  slides: shape({}).isRequired,
};

export default PresentationGroups;
