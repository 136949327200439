const loadScript = ({ onLoad, onError, id, src }) => {
  const firstScript = document.getElementsByTagName('script')[0];
  const existingScript = document.getElementById(id);
  if (existingScript) {
    if (onLoad) onLoad();
  } else {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    if (firstScript && firstScript.parentNode) {
      firstScript.parentNode.insertBefore(script, firstScript);
    } else {
      document.head.appendChild(script);
    }

    script.onload = () => {
      if (onLoad) onLoad();
    };
    script.onerror = (err) => {
      if (onError) onError(err);
    };
  }
};
export default loadScript;
