const Texts = {
  fileLabel: 'Template File',
  editLabel: 'Save',
  cancelLabel: 'Cancel',
  inProcess: 'Saving...',
  title: 'Import templates from Google',
  requiredError: (name) => `${name} is required`,
  selectPlaceholder: 'Select',
};

export default Texts;
