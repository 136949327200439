import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import routerMap from 'app/RouterMap';
import embeddablesRouterMap from 'app/Embeddables/EmbeddablesRouterMap';
import DashboardPage from 'app/Pages/Dashboard';
import ForgotUsernamePage from 'app/Pages/Auth/PublishedDemos';
import ResetPasswordPage from 'app/Pages/Auth/ResetPassword';
import LoginPage from 'app/Pages/Auth/Login';
import LibraryPage from 'app/Pages/Library';
import PublishedDemosPage from 'app/Pages/PublishedDemos';
import UserManagementPage from 'app/Pages/UserManagement';
import ReportsPage from 'app/Pages/Reports';
import MyAccountPage from 'app/Pages/MyAccount';
import InteractivesPage from 'app/Pages/Interactives';
import PublishedInteractivesPage from 'app/Pages/PublishedInteractives';
import ExperienceEditorPage from 'app/Pages/ExperienceEditor';

import InitEmbed from 'app/Embeddables/InitEmbed';
import DemosEmbeddable from 'app/Embeddables/Demos';
import EmbedDocs from 'app/Embeddables/Docs';
import PublishedDemoDetailsEmbeddable from 'app/Embeddables/PublishedDemoDetails';

import InteractiveAppsPage from 'app/Pages/InteractiveApps';
import InteractiveAdminPage from 'app/Pages/InteractiveAdmin';

import GSlidesPage from 'app/Pages/GSlides';

import gClient from 'utils/Google/GoogleClient';

import NotFound from 'common/Routes/NotFound';
import PrivateRoute from 'common/Routes/PrivateRoute';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function Main() {
  const [dynamicLibsLoaded, setLibs] = useState(false);

  useEffect(() => {
    gClient.init(() => setLibs(true));
  }, []);
  if (!dynamicLibsLoaded) return null;
  return (
    <div className="App">
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path={routerMap.dash.path}
            component={DashboardPage}
          />
          <PrivateRoute
            exact
            path={routerMap.interactive.path}
            component={InteractivesPage}
          />
          <Route
            exact
            path={routerMap.interactiveApp.path}
            component={InteractiveAppsPage}
          />
          <PrivateRoute
            exact
            path={routerMap.interactiveAppAdmin.path}
            component={InteractiveAdminPage}
          />
          <PrivateRoute
            exact
            path={routerMap.interactiveDetail.path}
            component={PublishedInteractivesPage}
          />
          <PrivateRoute
            exact
            path={routerMap.library.path}
            component={LibraryPage}
          />
          <PrivateRoute
            exact
            path={routerMap.demo.path}
            component={PublishedDemosPage}
          />
          <PrivateRoute
            exact
            path={routerMap.editor.path}
            component={ExperienceEditorPage}
          />
          <PrivateRoute
            exact
            path={routerMap.settings.path}
            component={UserManagementPage}
            requiredRole="admin"
          />
          <PrivateRoute
            exact
            path={routerMap.reports.path}
            component={ReportsPage}
          />
          <PrivateRoute
            exact
            path={routerMap.userProfile.path}
            component={MyAccountPage}
          />
          <PrivateRoute
            exact
            path={routerMap.gslides.path}
            component={GSlidesPage}
          />
          <Route exact path={routerMap.login.path} component={LoginPage} />
          <Route
            exact
            path={routerMap.forgotUsername.path}
            component={ForgotUsernamePage}
          />
          <Route
            exact
            path={routerMap.resetPassword.path}
            component={ResetPasswordPage}
          />

          <Route
            exact
            path={embeddablesRouterMap.init.path}
            component={InitEmbed}
          />
          <Route
            exact
            path={embeddablesRouterMap.demos.path}
            component={DemosEmbeddable}
          />
          <Route
            exact
            path={embeddablesRouterMap.publishedDemoDetails.path}
            component={PublishedDemoDetailsEmbeddable}
          />
          <Route
            exact
            path={embeddablesRouterMap.docs.path}
            component={EmbedDocs}
          />
          <NotFound />
        </Switch>
      </Router>
    </div>
  );
}

export default hot(module)(Main);
