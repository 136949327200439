import React, { useEffect, useMemo, useState } from 'react';
import { bool, func, number } from 'prop-types';
import { get } from 'lodash';
import Modal from 'react-bootstrap/Modal';

import { useRequest } from 'utils/Request';
import AuthModal from 'utils/Google/AuthModal';
import GTemplatesCRUD from 'app/Services/GSlides/GTemplatesCRUDService';
import GroupsOrder from 'app/Views/SlidesNavigation/components/GroupsOrder';
import InitialConfiguration from 'app/Views/SlidesNavigation/components/InitialConfiguration';
import PlaceholdersManagement from 'app/Views/SlidesNavigation/components/PlaceholdersManagement';
import SlidesGridEdit from 'app/Views/SlidesNavigation/components/SlidesGridEdit';
import SlidesSelection from 'app/Views/SlidesNavigation/components/SlidesSelection';
import PresentationGeneration from 'app/Views/SlidesNavigation/components/PresentationGeneration';
import { GeneratePresentationSteps } from './constants';

import './styles.scss';

function SlidesNavigation({ onCancel, show, templateId }) {
  const [activeStep, setActiveStep] = useState(
    GeneratePresentationSteps.GROUP_SELECTION,
  );
  const [genData, setGenData] = useState({
    groupsOrder: [],
    name: '',
    uploadedValues: {},
    selectedSlides: {},
  });
  const [presentation, setPresentation] = useState({});
  const [groups, setGroups] = useState([]);

  const selectedGroups = useMemo(() => {
    return groups.filter((g) => g.selected === true);
  }, [groups]);

  const getPresentation = useRequest(GTemplatesCRUD.byId, {
    onSuccess: (presentationData) => {
      setPresentation(presentationData);
      setGroups(presentationData.groups);
    },
    onError: () => {},
  });

  const handleFileUpload = (uploadedValues) => {
    const mappedUploadedValues = Object.entries(uploadedValues).reduce(
      (acc, [name, content]) => {
        return {
          ...acc,
          [name]: content.value,
        };
      },
      {},
    );
    setGenData({
      ...genData,
      uploadedValues: mappedUploadedValues,
    });
  };

  const updateGenData = (updatedGenData) => {
    setGenData({
      ...genData,
      ...updatedGenData,
    });
  };

  useEffect(() => {
    getPresentation.start(templateId);
    // getPresentationGrouping.start(presentationTemplate.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleGroup = (toggledGroup) => {
    const groupsToUpdate = [...groups];
    const groupToToggleIndex = groupsToUpdate.findIndex(
      (g) => g.id === toggledGroup.id,
    );

    groupsToUpdate[groupToToggleIndex].selected = toggledGroup.selected;
    setGroups(groupsToUpdate);
  };

  const updatePresentationName = (newPresentationName) => {
    updateGenData({ name: newPresentationName });
  };

  const nextStep = (step) => {
    setActiveStep(step);
  };
  return (
    <div>
      <AuthModal onCancel={onCancel}>
        <Modal
          size="lg"
          show={show}
          onHide={onCancel}
          dialogClassName="generate-presentation-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Generate Presentation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {getPresentation.inProcess && !getPresentation.error && (
              <div>Loading</div>
            )}
            {activeStep === GeneratePresentationSteps.GROUP_SELECTION && (
              <InitialConfiguration
                groups={groups}
                onGroupSelection={toggleGroup}
                next={nextStep}
                presentationName={genData.name}
                updatePresentationName={updatePresentationName}
              />
            )}
            {activeStep ===
              GeneratePresentationSteps.PLACEHOLDERS_MANAGEMENT && (
              <PlaceholdersManagement
                onFileUpload={handleFileUpload}
                groups={groups}
                jsonSchema={get(presentation, 'jsonSchema')}
                next={nextStep}
                presentationName={genData.name}
                slides={get(presentation, 'placeholder_schema.slides')}
              />
            )}
            {activeStep === GeneratePresentationSteps.SLIDE_EDIT && (
              <SlidesGridEdit
                groups={selectedGroups}
                next={nextStep}
                slides={get(presentation, 'placeholder_schema.slides')}
                updateGenData={updateGenData}
                uploadedValues={genData.uploadedValues}
              />
            )}
            {activeStep === GeneratePresentationSteps.SLIDE_SELECTION && (
              <SlidesSelection
                groups={selectedGroups}
                next={nextStep}
                slides={get(presentation, 'placeholder_schema.slides')}
                updateGenData={updateGenData}
              />
            )}
            {activeStep === GeneratePresentationSteps.GROUP_ORDER && (
              <GroupsOrder
                groups={selectedGroups}
                next={nextStep}
                updateGenData={updateGenData}
              />
            )}
            {activeStep === GeneratePresentationSteps.GENERATE && (
              <PresentationGeneration
                presentationData={{
                  ...genData,
                  presentation,
                  groups,
                }}
              />
            )}
          </Modal.Body>
          <Modal.Footer />
        </Modal>
      </AuthModal>
    </div>
  );
}

SlidesNavigation.propTypes = {
  onCancel: func.isRequired,
  show: bool,
  templateId: number.isRequired,
};

SlidesNavigation.defaultProps = {
  show: false,
};

export default SlidesNavigation;
