import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import GuestShell from 'app/Shells/Guest';
import ForgotUsernameForm from 'app/Forms/ForgotUsername';
import routerMap from 'app/RouterMap';
import SendUsername from 'app/Services/SendUsernameService';
import Texts from './texts';

function ForgotUsernamePage() {
  const history = useHistory();
  const [showConfirmation, setShow] = useState(false);

  useEffect(() => {
    return () => {
      setShow(false);
    };
  }, []);

  const handleSuccess = () => setShow(true);

  const sendUsername = (form, formikBag) => {
    SendUsername(form, formikBag, {
      onSuccess: handleSuccess,
    });
  };

  const handleCancel = () => {
    history.push(routerMap.login.getPath());
  };

  const handleContinue = () => {
    history.push(routerMap.login.getPath());
  };

  return (
    <GuestShell
      page={Texts.title}
      description={
        showConfirmation ? Texts.successDescription : Texts.description
      }
    >
      {showConfirmation ? (
        <div className="d-flex justify-content-end">
          <Button onClick={handleContinue}>{Texts.continueButton}</Button>
        </div>
      ) : (
        <ForgotUsernameForm onSubmit={sendUsername} onCancel={handleCancel} />
      )}
    </GuestShell>
  );
}

export default ForgotUsernamePage;
