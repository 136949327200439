/* eslint-disable no-unused-vars */
import http from 'utils/Http';
import gslidesSession from 'utils/GSlidesSession';

const PRESENTATION_BASE_URL = 'https://docs.google.com/presentation/d/';

const buildSections = (groups, selectedSlides) => {
  return groups.reduce((acc, group) => {
    const omit = [];

    if (group.selected) {
      group.slide_list.forEach((slideId) => {
        if (selectedSlides[slideId] === false) {
          omit.push(slideId);
        }
      });
    }

    acc[group.id] = {
      ignore: !group.selected,
      omit,
    };

    return acc;
  }, {});
};

const buildPlaceholders = (placeholders, uploadedValues) => {
  return placeholders.reduce((acc, placeholder) => {
    if (uploadedValues[placeholder.key]) {
      acc[placeholder.key] = {
        ...placeholder,
        value: uploadedValues[placeholder.key],
      };
    }

    return acc;
  }, {});
};

const GeneratePresentationService = async (presentationData, handlers) => {
  try {
    const accessToken = gslidesSession.getAccessToken();
    const payload = {
      id: presentationData.presentation.id,
      placeholders: buildPlaceholders(
        presentationData.presentation.placeholder_schema
          .placeholders_collection,
        presentationData.uploadedValues,
      ),
      sections: buildSections(
        presentationData.groups,
        presentationData.selectedSlides,
      ),
      name: presentationData.name,
      groups_order: presentationData.groupsOrder,
    };
    const { data: presentationGenRes } = await http.post({
      url: `api/v1/presentation-templates/${presentationData.presentation.id}/presentations`,
      body: {
        ...payload,
        code: accessToken,
      },
    });

    handlers.onSuccess({
      url: `${PRESENTATION_BASE_URL}${presentationGenRes.copyRes.id}`,
      name: presentationGenRes.copyRes.name,
    });
  } catch (err) {
    handlers.onError(err);
  }
};

export default GeneratePresentationService;
