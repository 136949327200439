const JsonToFileService = async (filename, content) => {
  const jsonContent = JSON.stringify(content, null, 2);
  const fileDownload = document.createElement('a');

  fileDownload.setAttribute(
    'href',
    `data:text/json;charset=utf-8,${encodeURIComponent(jsonContent)}`,
  );

  fileDownload.setAttribute('download', `${filename}.json`);

  if (document.createEvent) {
    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    fileDownload.dispatchEvent(event);
  } else {
    fileDownload.click();
  }
};

export default JsonToFileService;
