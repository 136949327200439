import React from 'react';
import { node } from 'prop-types';

function Cell({ children, ...other }) {
  return <td {...other}>{children}</td>;
}

Cell.propTypes = {
  children: node.isRequired,
};

export default Cell;
