import React from 'react';
import { string } from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

function LoadingSpinner({ message }) {
  return (
    <div className="loader loader-container d-flex justify-content-center align-items-center">
      <div>
        <div className="tmp-loader mb-2">{message}</div>
        <Spinner className="text-primary" animation="border" />
      </div>
    </div>
  );
}

LoadingSpinner.propTypes = {
  message: string,
};

LoadingSpinner.defaultProps = {
  message: 'Loading...',
};

export default LoadingSpinner;
