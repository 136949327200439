import React from 'react';
import { Formik } from 'formik';
import { bool, func, shape, string } from 'prop-types';
import { get } from 'lodash';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import SubmitErrorMessage from 'common/Message/SubmitError';
import useFilterLoader from 'utils/useFilterLoader';
import filtersService from 'app/Services/FiltersService';
import MultiCreatableSelect from 'common/Inputs/MultiCreatableSelect';
import Texts from './texts';
import './style.scss';

const validationSchema = yup.object({
  client: yup
    .object({ name: yup.string() })
    .nullable()
    .test('clientCustomTest', null, (obj) => {
      if (obj && obj.name !== undefined) {
        return true;
      }

      return new yup.ValidationError(
        Texts.requiredError(Texts.clientNameLabel),
        null,
        'client',
      );
    }),
});

function PublishDemoForm({
  onCancel,
  onSubmit,
  interactivePublished,
  interactiveName,
  show,
}) {
  const clientOptionsLoader = useFilterLoader(
    !interactivePublished.id,
    filtersService.clients,
  );
  const clientOptions = get(clientOptionsLoader, 'options', []);
  return (
    <Modal size="sm" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{Texts.createTitle}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={interactivePublished}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, errors, isSubmitting, values, setFieldValue }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor="client">
                        {Texts.clientNameLabel}
                      </Form.Label>
                      <MultiCreatableSelect
                        isClearable
                        isDisabled={!!interactivePublished.id}
                        labelField="name"
                        name="client"
                        options={clientOptions}
                        placeholder="Clients"
                        setFieldValue={setFieldValue}
                        value={get(values, 'client')}
                        valueField="id"
                        className={errors.client ? 'invalid-field' : ''}
                      />
                      {errors.client && (
                        <div className="display-feedback">{errors.client}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>{Texts.interactiveNameLabel}</Form.Label>
                      <Form.Control
                        name="interactive_name"
                        type="text"
                        value={interactiveName}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Modal.Body>
              {!isSubmitting && errors.internalServerError && (
                <SubmitErrorMessage>
                  {errors.internalServerError}
                </SubmitErrorMessage>
              )}
              <Modal.Footer>
                <AcceptCancelButton
                  isProcessing={isSubmitting}
                  acceptLabel={
                    interactivePublished.id
                      ? Texts.editLabel
                      : Texts.acceptLabel
                  }
                  onCancel={onCancel}
                  onAccept={handleSubmit}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

PublishDemoForm.propTypes = {
  interactiveName: string.isRequired,
  interactivePublished: shape({}),
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  show: bool,
};

PublishDemoForm.defaultProps = {
  show: false,
  interactivePublished: {
    id: null,
    client: undefined,
  },
};

export default PublishDemoForm;
