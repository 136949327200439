import React, { useEffect, useState } from 'react';
import { useRequest } from 'utils/Request';
import fromQS from 'utils/fromQS';
import { useParams } from 'react-router-dom';

import LoadingSpinner from 'common/LoadingSpinner';
import interactiveService from 'app/Services/InteractiveCRUDService';
import publishService from 'app/Services/PublishedInteractiveCRUDService';
import { bool } from 'prop-types';
import './styles.scss';

function EmbeddedInteractive({ isAdmin }) {
  const [iframeUrl, setIframe] = useState(null);
  const { id } = useParams();
  const loadInteractive = useRequest(interactiveService.byId, {
    onSuccess: (interactive) => {
      const url = `${interactive.prototype_url}?isAdmin=${isAdmin}&id=${id}`;
      setIframe(url);
    },
    onError: () => {},
  });
  const loadPublication = useRequest(publishService.byId, {
    onSuccess: (publication) => {
      const url = `${publication.interactive.prototype_url}?isAdmin=${isAdmin}&id=${id}`;
      setIframe(url);
    },
    onError: () => {},
  });

  useEffect(() => {
    const isTemplate = fromQS('isTemplate');
    const service = isTemplate ? loadInteractive : loadPublication;
    service.start(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return iframeUrl ? (
    <iframe
      className="interactive-iframe-app"
      src={iframeUrl}
      title="Interactive"
    />
  ) : (
    <div className="pt-5">
      <LoadingSpinner />
    </div>
  );
}

EmbeddedInteractive.propTypes = {
  isAdmin: bool.isRequired,
};

export default EmbeddedInteractive;
