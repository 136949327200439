import React from 'react';
import { Link } from 'react-router-dom';

import routerMap from 'app/RouterMap';
import GuestShell from 'app/Shells/Guest';
import LoginForm from 'app/Forms/Login';

import Texts from './texts';

function LoginPage() {
  return (
    <GuestShell page={Texts.title}>
      <LoginForm />
      <div className="text-right">
        <Link
          to={routerMap.resetPassword.getPath()}
          className="px-0 py-1 d-block"
        >
          {Texts.forgotPassword}
        </Link>
        <Link
          to={routerMap.forgotUsername.getPath()}
          className="px-0 py-0 d-block"
        >
          {Texts.forgotUsername}
        </Link>
      </div>
    </GuestShell>
  );
}

export default LoginPage;
