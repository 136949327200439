import { SortableHeader, Header, LinkCell, Cell } from 'common/DataTable';

export const tRows = [
  {
    headerComponent: SortableHeader,
    headerValue: 'presentation_name',
    headerLabel: 'Name',
    key: 'presentation_name',
    cellComponent: Cell,
  },
  {
    headerComponent: Header,
    headerValue: 'presentation_id',
    headerLabel: 'Presentation Id',
    key: 'presentation_id',
    cellComponent: LinkCell,
  },
];
