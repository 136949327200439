import { useState, useEffect } from 'react';
import metricsService from 'app/Services/MetricsService';

function useDemoStatsState(demoId) {
  const [metrics, setMetrics] = useState({});

  const loadMetrics = () => {
    metricsService.publishedDemos(demoId, {
      onSuccess: (metricsData) => {
        setMetrics(metricsData);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    loadMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    metrics,
  };
}

export default useDemoStatsState;
