/* eslint-disable no-unused-vars */
import http from 'utils/Http';
import gsession from 'utils/GSlidesSession';

import { handleFormErrors } from '../utils';

const ImportTemplate = async (form, handlers, formikBag) => {
  try {
    const code = gsession.getAccessToken();
    const payload = {
      code,
      presentation_id: form.template_file.id,
    };
    if (form.template_file.templateId) {
      payload.id = form.template_file.templateId;
    }
    await http.post({
      url: 'api/v1/presentation-templates/import',
      body: payload,
    });
    handlers.onSuccess(form.template_file);
  } catch (error) {
    if (formikBag) handleFormErrors(error, formikBag);
  } finally {
    if (formikBag) formikBag.setSubmitting(false);
  }
};

export default ImportTemplate;
