/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import { any, bool, func } from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';

function SwitchValue({ value, id, checked, onChange, disabled }) {
  const handleClick = () => onChange(value, checked);

  return (
    <Form.Switch
      id={id}
      label=""
      checked={checked}
      onChange={handleClick}
      disabled={disabled}
    />
  );
}

SwitchValue.propTypes = {
  checked: bool,
  disabled: bool,
  id: any,
  onChange: func,
  value: any,
};

export default SwitchValue;
