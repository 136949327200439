const texts = {
  addGroupErrorTitle: 'Error Creating Group',
  addGroupErrorMessage: 'Could not create group.',
  updateGroupErrorTitle: 'Error Updating Group',
  updateGroupErrorMessage: 'Could not update group.',
  deleteGroupTitle: 'Delete Group',
  deleteGroupDescription:
    'The group will be deleted and the slides will be moved to the default "Library" group.',
  deleteFailedTitle: "Couldn't process the remove operation",
  deleteFailedDescription: 'Something went wrong',
  groupMoveFailedTitle: 'Error Moving Group',
  groupMoveFailedDescription: 'Could not move the group',
};

export default texts;
