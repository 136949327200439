import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { func, number } from 'prop-types';

function AddGroupInput({ templateId, onAdd }) {
  const [newGroupName, setNewGroupName] = useState('');
  const [submitting, setSubmitting] = useState(null);
  const handleOnAdded = () => {
    setSubmitting(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOnAdded();
    }
  };

  useEffect(() => {
    if (submitting && newGroupName) {
      const newGroup = {
        presentation_templates_id: templateId,
        section_name: newGroupName,
        slide_list: [],
      };
      onAdd(newGroup, { setSubmitting });
    }

    if (submitting === false && newGroupName) {
      setNewGroupName('');
      setSubmitting(null);
    }
  }, [submitting, newGroupName]);

  // NOT PART OF THIS COMPONENT
  const handleNewGroupNameUpdate = (e) => {
    setNewGroupName(e.target.value);
  };

  return (
    <div className="d-flex mb-2">
      <div className="d-flex w-50">
        <Form.Control
          type="text"
          value={newGroupName}
          placeholder="New Group Name"
          onChange={handleNewGroupNameUpdate}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div>
        <Button className="d-flex ml-1" onClick={handleOnAdded}>
          Add Group
        </Button>
      </div>
    </div>
  );
}

AddGroupInput.propTypes = {
  onAdd: func.isRequired,
  templateId: number.isRequired,
};

export default AddGroupInput;
