import React from 'react';
import { oneOfType, string, node } from 'prop-types';
import cx from 'classnames';
import session from 'utils/Session';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import './styles.scss';

function Shell({ page, children }) {
  const sidebarExpanded = true;
  const sidebarClasses = cx(
    'demo-library-app-sidebar-wrapper',
    sidebarExpanded && 'demo-library-app-sidebar-wrapper--is-expanded',
  );
  const headerClasses = cx(
    'demo-library-app-header-wrapper',
    sidebarExpanded && 'demo-library-app-header-wrapper--is-expanded',
  );
  const username = session.getUsername();

  return (
    <div className="demo-library-app">
      <div className={sidebarClasses}>
        <Sidebar />
      </div>
      <div className={headerClasses}>
        <Header page={page} username={username} />
        <div className="header-top-fill bg-dark" />
      </div>
      <div
        className={cx(
          'demo-library-app-viewport px-4',
          'demo-library-app-viewport--is-expanded',
        )}
      >
        {children}
      </div>
    </div>
  );
}

Shell.propTypes = {
  children: node.isRequired,
  page: oneOfType([string, node]).isRequired,
};

export default Shell;
