/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { get, pick, isEmpty } from 'lodash';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';

import JsonToFile from 'utils/jsonToFile';
import { FileEarmarkArrowDownI, FileEarmarkArrowUpI } from 'common/icons';
import Button from 'common/Buttons/Button';
import FileUpload from 'common/Inputs/FileUpload';
import { GeneratePresentationSteps } from 'app/Views/SlidesNavigation/constants';

import Texts from './texts';

const getPlaceholdersFromSelectedGroups = (slides, jsonSchema, groups) => {
  const placeholdersToInclude = groups.reduce((acc, group) => {
    if (group.selected) {
      const placeholders = group.slide_list.flatMap((slideId) => {
        return get(slides, `[${slideId}].placeholder_list`, []).map(
          (placeholder) => {
            return placeholder.key;
          },
        );
      });
      return [...acc, ...placeholders];
    }
    return acc;
  }, []);

  return pick(jsonSchema, placeholdersToInclude);
};

const buildValidationSchema = (requiredPlaceholders) => {
  const rules = Object.keys(requiredPlaceholders).reduce((acc, placeholder) => {
    acc[placeholder] = yup.object().shape({
      value: yup.string().required(`${placeholder} is required`),
    });

    return acc;
  }, {});

  const schema = { file: yup.object().shape(rules) };

  return yup.object(schema);
};

function PlaceholdersManagement({
  groups,
  jsonSchema,
  next,
  onFileUpload,
  presentationName,
  slides,
}) {
  const handleGenerateAllPlaceholders = () => {
    JsonToFile(`${presentationName}-all-placeholders`, jsonSchema);
  };

  const requiredPlaceholders = getPlaceholdersFromSelectedGroups(
    slides,
    jsonSchema,
    groups,
  );

  const handleGeneratePlaceholdersFromSelectedGroups = () => {
    JsonToFile(
      `${presentationName}-selected-topics-placeholders`,
      requiredPlaceholders,
    );
  };

  const handleFormSubmit = (form) => {
    onFileUpload(form.file);
    next(GeneratePresentationSteps.SLIDE_EDIT);
  };

  const goBack = () => {
    next(GeneratePresentationSteps.GROUP_SELECTION);
  };
  return (
    <div className="d-flex justify-content-between">
      <div>
        <Form.Label className="font-weight-bold">
          {Texts.downloadTitle}
        </Form.Label>
        <div className="d-flex justify-content-center my-3">
          <FileEarmarkArrowDownI size={50} />
        </div>
        <div className="justify-content-center">
          <div
            className="link d-flex align-items-center"
            onClick={handleGenerateAllPlaceholders}
          >
            <FileEarmarkArrowDownI />
            {Texts.allPlaceholders}
          </div>
          <div
            className="link d-flex align-items-center"
            onClick={handleGeneratePlaceholdersFromSelectedGroups}
          >
            <FileEarmarkArrowDownI />
            {Texts.selectedTopics}
          </div>
        </div>
      </div>
      <div>
        <Form.Label className="font-weight-bold">
          {Texts.uploadTitle}
          <span className="text-danger">*</span>
        </Form.Label>
        <div className="d-flex justify-content-center my-3">
          <FileEarmarkArrowUpI size={50} />
        </div>
        <div>
          <Formik
            initialValues={{ file: {} }}
            onSubmit={handleFormSubmit}
            validationSchema={buildValidationSchema(requiredPlaceholders)}
          >
            {({
              handleSubmit,
              handleBlur,
              setFieldValue,
              submitCount,
              errors,
              values,
            }) => {
              return (
                <Form noValidate>
                  <Form.Group>
                    <Form.File id="placeholders-upload-file">
                      <FileUpload
                        name="file"
                        accept=".json"
                        className="cx-input"
                        value={values.file}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                      />
                    </Form.File>
                    {!!submitCount && errors.file && (
                      <div className="text-danger mt-1 cx-input">
                        <div>
                          The following required placeholders are missing:
                        </div>
                        <div className="p-2">
                          {Object.keys(errors.file).map((error) => {
                            return <div>{`* ${error}`}</div>;
                          })}
                        </div>
                      </div>
                    )}
                  </Form.Group>
                  <div className="d-flex justify-content-end">
                    <Button
                      size="md"
                      variant="secondary"
                      onClick={goBack}
                      className="mr-2 panel-form-button"
                    >
                      {Texts.return}
                    </Button>
                    <Button
                      variant="primary"
                      size="md"
                      className="panel-form-button"
                      onClick={handleSubmit}
                      disabled={isEmpty(values.file)}
                    >
                      {Texts.continue}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

PlaceholdersManagement.propTypes = {
  groups: arrayOf(
    shape({
      selected: bool,
      slide_list: arrayOf(string).isRequired,
    }),
  ).isRequired,
  jsonSchema: shape({}).isRequired,
  next: func.isRequired,
  onFileUpload: func.isRequired,
  presentationName: string.isRequired,
  slides: shape({}).isRequired,
};

export default PlaceholdersManagement;
