/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { bool, func, node } from 'prop-types';

import './styles.scss';

function HotspotLayer({ allowNewHotspots, children, onAddHotspot }) {
  const handleOnClick = (event) => {
    if (!allowNewHotspots) {
      return;
    }

    const { offsetX: x, offsetY: y } = event.nativeEvent || {};
    const position = {
      x,
      y,
    };
    onAddHotspot(position);
  };

  return (
    <div className="hotspot-layer" onClick={handleOnClick}>
      {children}
    </div>
  );
}

HotspotLayer.propTypes = {
  allowNewHotspots: bool,
  children: node.isRequired,
  onAddHotspot: func.isRequired,
};

HotspotLayer.defaultProps = {
  allowNewHotspots: true,
};

export default HotspotLayer;
