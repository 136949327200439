import React from 'react';
import { any, func, node, string } from 'prop-types';
import cx from 'classnames';
import Cell from 'common/DataTable/Cell';

function LinkCell({ children, className, onClick, value }) {
  const handleClick = () => onClick(value, 'LINK');

  return (
    <Cell
      className={cx('bold link', className)}
      role="presentation"
      onClick={handleClick}
    >
      <span className="link">{children}</span>
    </Cell>
  );
}

LinkCell.propTypes = {
  children: node.isRequired,
  className: string,
  onClick: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
};

LinkCell.defaultProps = {
  className: null,
  value: null,
};

export default LinkCell;
