import React, { useState } from 'react';
import { func, number, shape } from 'prop-types';
import Carousel from 'react-bootstrap/Carousel';

import SlideEdit from '../SlideEdit';

import './styles.scss';

function SlidesCarousel({
  slides,
  onUpdateValues,
  openAtIndex,
  uploadedValues,
}) {
  const [displayIndex, setDisplayIndex] = useState(openAtIndex);

  const handleValuesUpdate = (updatedValues) => {
    onUpdateValues(updatedValues);
  };

  const handleNextSlide = (nextSlideIndex) => {
    setDisplayIndex(nextSlideIndex);
  };

  return (
    <div>
      <Carousel
        interval={100000}
        activeIndex={displayIndex}
        onSelect={handleNextSlide}
      >
        {Object.keys(slides).map((slideId) =>
          slides[slideId] ? (
            <Carousel.Item key={slideId}>
              <SlideEdit
                id={slideId}
                onUpdateValues={handleValuesUpdate}
                placeholders={slides[slideId].placeholder_list}
                thumbnailUrl={slides[slideId].imageURL}
                uploadedValues={uploadedValues}
              />
            </Carousel.Item>
          ) : (
            ''
          ),
        )}
      </Carousel>
    </div>
  );
}

SlidesCarousel.propTypes = {
  onUpdateValues: func.isRequired,
  openAtIndex: number,
  slides: shape({}).isRequired,
  uploadedValues: shape({}),
};

SlidesCarousel.defaultProps = {
  openAtIndex: 0,
  uploadedValues: {},
};

export default SlidesCarousel;
