import CRUDService from 'app/Services/CRUDService';

class GTemplatesCRUD extends CRUDService {
  constructor() {
    super({
      mapEntityFrom: (data = {}) => ({
        ...data,
      }),
      mapListFrom: (data) => data,
      mapTo: (data) => ({
        ...data,
      }),
      buildUrl: (suffix) =>
        `api/v1/presentation-templates${suffix ? `${suffix}` : ''}`,
    });
  }
}

export default new GTemplatesCRUD();
