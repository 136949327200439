import { useState, useEffect } from 'react';
import demoService from 'app/Services/DemoCRUDService';
import metricsService from 'app/Services/MetricsService';
import safeInvoke from 'utils/safeInvoke';

function useHeaderState({ listeners = {} }) {
  const { onSave, onSaveError } = listeners || {};
  const [totalDemos, setTotalDemos] = useState(0);

  const loadDemosCount = () => {
    metricsService.demosCount({
      onSuccess: (demosCount) => {
        setTotalDemos(demosCount.totalDemos);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    loadDemosCount();
  }, []);

  const addDemo = (demoForm, formikBag) => {
    demoService.create(demoForm, formikBag, {
      onSuccess: (createdDemo) => {
        safeInvoke(onSave, createdDemo);
      },
      onError: () => {
        safeInvoke(onSaveError);
      },
    });
  };

  return {
    totalDemos,
    addDemo,
    reloadCount: loadDemosCount,
  };
}

export default useHeaderState;
