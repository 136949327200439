import React from 'react';
import { Formik } from 'formik';
import { arrayOf, func, shape, string } from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';

import Button from 'common/Buttons/Button';

import { CardImageI } from 'common/icons';
import Slide from 'app/Views/Slide';
import './styles.scss';

const requiredMessage = (key) => `${key} is required`;

const placeholderTypeRules = {
  text: (key) => yup.string().required(requiredMessage(key)),
  image: (key) =>
    yup.string().url('Please enter a valid URL').required(requiredMessage(key)),
};

const buildValidationSchema = (placeholders) => {
  const rules = placeholders.reduce((acc, currentValue) => {
    return {
      ...acc,
      [currentValue.key]: placeholderTypeRules[currentValue.type](
        currentValue.key,
      ),
    };
  }, {});

  return yup.object(rules);
};

function SlideEdit({
  id,
  onUpdateValues,
  placeholders,
  thumbnailUrl,
  uploadedValues,
}) {
  const handleUpdate = (formValues) => {
    onUpdateValues(formValues);
  };

  const validationSchema = buildValidationSchema(placeholders);

  return (
    <div>
      <div className="d-flex">
        <div className="w-50 mr-2 slide-thumbnail">
          <Slide id={id} thumbnailUrl={thumbnailUrl} />
        </div>
        <div className="w-50">
          {placeholders.length > 0 && (
            <Formik
              enableReinitialize
              initialValues={uploadedValues}
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
            >
              {({
                handleBlur,
                handleSubmit,
                handleChange,
                errors,
                touched,
                values,
              }) => {
                return (
                  <Form noValidate>
                    <Modal.Body>
                      <Form.Row>
                        {placeholders.map((placeholder) => (
                          <Col sm={12} key={`${id}_${placeholder.key}`}>
                            <Form.Group>
                              <Form.Label>
                                {placeholder.type === 'image' && (
                                  <CardImageI className="mr-1" />
                                )}
                                {placeholder.key}
                              </Form.Label>
                              <Form.Control
                                name={placeholder.key}
                                type="text"
                                onChange={handleChange}
                                value={values[placeholder.key]}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched[placeholder.key] &&
                                  errors[placeholder.key]
                                }
                              />
                              {touched[placeholder.key] &&
                                errors[placeholder.key] && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors[placeholder.key]}
                                  </Form.Control.Feedback>
                                )}
                            </Form.Group>
                          </Col>
                        ))}
                      </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={handleSubmit}>
                        Update
                      </Button>
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

SlideEdit.propTypes = {
  id: string.isRequired,
  onUpdateValues: func.isRequired,
  placeholders: arrayOf(
    shape({
      key: string.isRequired,
      type: string.isRequired,
    }),
  ),
  thumbnailUrl: string.isRequired,
  uploadedValues: shape({}).isRequired,
};

SlideEdit.defaultProps = {
  placeholders: [],
};

export default SlideEdit;
