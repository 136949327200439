export const PLACEMENT = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

export const PlacementOptions = ['top', 'bottom', 'left', 'right'];

export const HOTSPOT_EVENTS = {
  COMMENTARY: 'commentary',
  CLICK: 'click',
  TIMER: 'timer',
  EMBED: 'embed',
};

export const MODALS = {
  CONCLUSION_MODAL: 'conclusion',
  INTRO_MODAL: 'intro',
  STEP_MODAL: 'step',
  HOTSPOT_MODAL: 'hotspot',
  CREATE_FLOW_MODAL: 'createFlow',
  DELETE_FLOW_CONFIRMATION: 'deleteFlowConfirmation',
  DELETE_HOTSPOT_CONFIRMATION: 'deleteHotspotConfirmation',
  DELETE_STEP_CONFIRMATION: 'deleteStepConfirmation',
};

export const DEFAULT_FORMAT = {
  color: '#ffffff',
  background: '#8c558e',
  fontFamily: 'arial,helvetica,sans-serif',
  fontSize: '10pt',
};
