/* eslint-disable camelcase */
import React from 'react';
import { node, string } from 'prop-types';
import { to_MMDDYYYYHHMMA } from 'utils/dates';
import Cell from 'common/DataTable/Cell';

function DateCell({ children, className }) {
  return <Cell className={className}>{to_MMDDYYYYHHMMA(children)}</Cell>;
}

DateCell.propTypes = {
  children: node.isRequired,
  className: string,
};

DateCell.defaultProps = {
  className: null,
};

export default DateCell;
