import http from 'utils/Http';
import CRUDService from 'app/Services/CRUDService';

const mapItem = (group) => {
  return {
    id: group.id,
    presentation_templates_id: group.presentation_templates_id,
    slide_list: group.slide_list,
    section_name: group.section_name,
  };
};

const mapListFrom = (groups = []) => {
  return groups.map((option) => mapItem(option));
};

class PresentationGroupingCRUD extends CRUDService {
  constructor() {
    super({
      mapEntityFrom: (data) => mapItem(data),
      mapListFrom: (data) => mapListFrom(data),
      mapTo: (data) => data,
      buildUrl: (suffix) =>
        `api/v1/presentation-grouping${suffix ? `${suffix}` : ''}`,
    });
  }

  getByPresentationId = async (presentationId, handlers) => {
    try {
      const { data: groups } = await http.get({
        url: `api/v1/presentation-templates/${presentationId}/presentation-grouping`,
      });

      const mappedGroups = mapListFrom(groups);

      handlers.onSuccess(mappedGroups);
    } catch (err) {
      handlers.onError();
    }
  };

  moveSlide = async (newGroupForm, handlers) => {
    try {
      const { data: updatedGroups } = await http.post({
        url: `/api/v1/presentation-grouping/slides/move`,
        body: newGroupForm,
      });

      const mappedGroups = mapListFrom(updatedGroups);

      handlers.onSuccess(mappedGroups);
    } catch (err) {
      handlers.onError();
    }
  };

  removeGroup = async (groupId, handlers) => {
    try {
      const { data: updatedGroups } = await http.post({
        url: `/api/v1/presentation-grouping/${groupId}/remove`,
      });

      const mappedGroups = mapListFrom(updatedGroups);

      handlers.onSuccess(mappedGroups);
    } catch (err) {
      handlers.onError();
    }
  };

  moveGroup = async (groupToMove, handlers) => {
    try {
      const { data: updatedGroups } = await http.post({
        url: `/api/v1/presentation-grouping/groups/${groupToMove.groupId}/move`,
        body: {
          presentation_templates_id: groupToMove.presentation_templates_id,
          dest_index: groupToMove.destinationIndex,
        },
      });

      handlers.onSuccess(updatedGroups);
    } catch (err) {
      handlers.onError();
    }
  };
}

export default new PresentationGroupingCRUD();
