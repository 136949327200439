/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable camelcase */
import React from 'react';
import { number } from 'prop-types';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import Button from 'common/Buttons/Button';
import Modal from 'react-bootstrap/Modal';
import {
  EyeFill as AddIcon,
  XCircle as DeleteIcon,
} from 'react-bootstrap-icons';
import { get } from 'lodash';
import DataTable from 'common/DataTable';
import useSearchState from 'utils/useSearchState';
import editorService from 'app/Services/DemoEditorService';
import useToggle from 'utils/useToggleState';
import useAsyncLoad from 'utils/useAsyncLoad';
import useMutation from 'utils/useMutation';
import AsyncSelect from 'common/Inputs/AsyncSelect';

import useAddEditors from './useAddEditors';
import Texts from './texts';
import './styles.scss';

function DemoEditorsView({ demoId }) {
  const toggleInvite = useToggle(false);
  const loadAvailableEditors = useAsyncLoad(
    editorService.getAvailableEditorsForDemo(demoId),
    { limit: 5 },
  );
  const searchDemoEditors = useSearchState(
    editorService.byDemoId(demoId),
    ['q'],
    undefined,
    {
      limit: 5,
    },
  );

  const close = () => {
    toggleInvite.turnOff();
  };

  const addEditors = useAddEditors(demoId, () => {
    close();
    searchDemoEditors.reload();
  });

  const removeEditorMutation = useMutation(editorService.remove, {
    onSuccess: () => searchDemoEditors.reload(),
    onError: () => null,
  });

  return (
    <>
      <Button
        variant="secondary"
        className="mr-2"
        onClick={toggleInvite.onToggle}
      >
        <AddIcon className="text-red add-editor-icon mr-1" />
        {Texts.modalTitle}
      </Button>
      <Modal size="md" show={toggleInvite.isActive} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{Texts.modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="mt-2">
          <AsyncSelect
            isMulti
            loadOptions={loadAvailableEditors}
            onChange={addEditors.change}
            label={Texts.inviteDescription}
            placeholder={Texts.selectPlaceholder}
          />
          <div className="mt-4 editors-results">
            <DataTable
              hide="limit"
              menuPosition="bottom"
              onLimitChange={searchDemoEditors.changeLimit}
              onPageChange={searchDemoEditors.changePage}
              onReload={searchDemoEditors.reload}
              total={get(searchDemoEditors, 'query.result.total', 0)}
              page={get(searchDemoEditors, 'query.page')}
              limit={get(searchDemoEditors, 'query.limit')}
              isFetching={get(searchDemoEditors, 'query.isLoading')}
              hasError={get(searchDemoEditors, 'query.error')}
            >
              <thead>
                <th>Name</th>
                <th>Email</th>
                <th />
              </thead>
              {!searchDemoEditors.query.isLoading && (
                <tbody>
                  {get(searchDemoEditors, 'query.result.data', []).map(
                    (editor) => (
                      <tr key={editor.id}>
                        <td className="bold">{editor.username}</td>
                        <td>{editor.email}</td>
                        <td>
                          <Button
                            className="float-right px-2 py-1 text-white"
                            value={editor.id}
                            onClick={removeEditorMutation.mutate}
                          >
                            <DeleteIcon className="remove-editor-button" />
                          </Button>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              )}
            </DataTable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AcceptCancelButton
            disabled={addEditors.isEmpty}
            isProcessing={addEditors.inProcess}
            acceptLabel={Texts.inviteBtn}
            onCancel={close}
            onAccept={addEditors.submit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

DemoEditorsView.propTypes = {
  demoId: number.isRequired,
};

export default DemoEditorsView;
