import React from 'react';
import Shell from 'app/Shells/Management';
import routerMap from 'app/RouterMap';
import Texts from './texts';
import './styles.scss';

function Http404Page() {
  return (
    <Shell page="">
      <div className="http-container">
        <div className="center-container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="mr-3 pr-3 status-code d-flex align-items-center">
              <h1>404</h1>
            </div>
            <div className="status-message">
              <h4 className="mt-1">{Texts.title}</h4>
              <a href={routerMap.dash.getPath()} rel="noopener noreferrer">
                {Texts.message}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Shell>
  );
}

export default Http404Page;
