import { useState, useEffect } from 'react';

function useMetricsLoader(service) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [metrics, setMetrics] = useState({});

  const load = (clientId, demoId) => {
    setIsLoading(true);
    service(clientId, demoId, {
      onSuccess: (data) => {
        setMetrics(data);
        setIsLoading(false);
        setError(false);
      },
      onError: () => {
        setError(true);
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filter = (clientId, demoId) => {
    load(clientId, demoId);
  };

  return {
    metrics,
    isLoading,
    error,
    load,
    filter,
  };
}

export default useMetricsLoader;
