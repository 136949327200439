import React, { useState, useEffect } from 'react';
import { func, node } from 'prop-types';
import gslidesSession from 'utils/GSlidesSession';
import GLogin from 'utils/Google/GLogin';
import Modal from 'react-bootstrap/Modal';
import Texts from './texts';
import './styles.scss';

// render children if authenticated or after auth
function AuthModal({ children, onCancel, onSigned }) {
  const [showModal, setShowModal] = useState(!gslidesSession.isSignedIn());
  const [message, setMessage] = useState(Texts.loginMessage);
  const [isSignedIn, setIsSignedIn] = useState(gslidesSession.isSignedIn());

  const handleLoginSuccess = () => {
    setIsSignedIn(true);
    setShowModal(false);
  };
  const handleLoginFailure = () => {
    setMessage(Texts.loginErrorMessage);
  };
  const handleCancel = () => {
    setMessage(Texts.loginMessage);
    onCancel();
  };

  useEffect(() => {
    if (isSignedIn && onSigned) onSigned();
  }, [isSignedIn]);

  return isSignedIn && !gslidesSession.hasExpired() ? (
    children
  ) : (
    <Modal size="md" show={showModal} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{Texts.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="glogin d-flex justify-content-center align-items-center">
          <div>
            <div className="d-flex justify-content-center">
              <GLogin
                buttonText="Login"
                onLoginSuccess={handleLoginSuccess}
                onLoginError={handleLoginFailure}
              />
            </div>
            <div className="sub-text mt-1">{message}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

AuthModal.propTypes = {
  children: node,
  onCancel: func.isRequired,
  onSigned: func,
};

AuthModal.defaultProps = {
  children: null,
  onSigned: undefined,
};

export default AuthModal;
