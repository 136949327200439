import { useState, useEffect, useMemo } from 'react';
import clipboard from 'clipboard-polyfill';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import useSearchState from 'utils/useSearchState';
import safeInvoke from 'utils/safeInvoke';
import demoService from 'app/Services/DemoCRUDService';
import publishedDemoService from 'app/Services/PublishedDemoCRUDService';

function useDemoState() {
  const [demo, setDemo] = useState(null);
  const { id: demoId } = useParams();
  const search = useSearchState(
    (qs, handler) => publishedDemoService.allByDemo(demoId, qs, handler),
    ['q'],
    undefined,
    {
      sort: [{ field: 'visits', order: 'desc' }],
    },
  );
  const getPublishedDemos = () => get(search, 'query.result.data', []);
  const [publishedDemos, setPublishedDemos] = useState(getPublishedDemos());

  useEffect(() => {
    setPublishedDemos(getPublishedDemos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.query.result]);

  useEffect(() => {
    if (!demo) {
      demoService.byId(demoId, {
        onSuccess: (demoResponse) => {
          setDemo(demoResponse);
        },
        onError: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templateVersion = useMemo(() => get(demo, 'template.tour.version'), [
    demo,
  ]);

  const savePublishedDemo = (publishedDemoForm, formikBag, listeners) => {
    const service = publishedDemoForm.id
      ? publishedDemoService.edit
      : demoService.publish;
    return service(publishedDemoForm, formikBag, listeners);
  };

  const urlToClipboard = (publishedDemo) => {
    clipboard.writeText(publishedDemo.demo_unique_url);
  };

  const toggleArchive = () => {
    demoService.toggleArchive(demo, {
      onSuccess: (updatedDemo) => {
        setDemo(updatedDemo);
      },
    });
  };

  const completeDemo = () => {
    demoService.complete(demo.id, {
      onSuccess: (updatedDemo) => {
        setDemo(updatedDemo);
      },
    });
  };

  const refreshTour = (publishedDemo, handlers) => {
    publishedDemoService.refreshTour(publishedDemo.id, handlers);
  };

  const updatePublishedDemoInData = (updatedPublishedDemo) => {
    setPublishedDemos(
      publishedDemos.map((pd) => {
        return pd.id === updatedPublishedDemo.id
          ? { ...pd, ...updatedPublishedDemo }
          : pd;
      }),
    );
  };

  const updatePublishedDemo = (publishedDemo, handlers) => {
    publishedDemoService.update(publishedDemo, {
      onSuccess: (updatedPublishedDemo) => {
        updatePublishedDemoInData(updatedPublishedDemo);
        safeInvoke(handlers.onSuccess, updatedPublishedDemo);
      },
      onError: () => {
        safeInvoke(handlers.onError);
      },
    });
  };

  return {
    demo,
    query: search.query,
    publishedDemos,
    templateVersion,
    savePublishedDemo,
    urlToClipboard,
    toggleArchive,
    completeDemo,
    reload: search.reload,
    search: search.change,
    changePage: search.changePage,
    changeSort: search.changeSort,
    changeLimit: search.changeLimit,
    filter: search.changeFilter,
    refreshTour,
    updatePublishedDemo,
    updatePublishedDemoInData,
  };
}

export default useDemoState;
