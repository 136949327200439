const Texts = {
  clientNameLabel: 'Client Name',
  interactiveNameLabel: 'Interactive',
  acceptLabel: 'Publish',
  editLabel: 'Save',
  cancelLabel: 'Cancel',
  inProcess: 'Saving...',
  editTitle: 'Edit Interactive',
  createTitle: 'Publish Interactive',
  requiredError: (name) => `${name} is required`,
};

export default Texts;
