import React, { useMemo, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Checkbox from 'common/Inputs/Checkboxes/Checkbox';
import Button from 'common/Buttons/Button';
import PrimitiveButton from 'common/Buttons/Primitive';

import { GeneratePresentationSteps } from 'app/Views/SlidesNavigation/constants';

import Texts from './texts';
import './styles.scss';

function InitialConfiguration({
  groups,
  next,
  onGroupSelection,
  presentationName,
  updatePresentationName,
}) {
  const [presentationNameField, setPresentationNameField] = useState(
    presentationName,
  );
  const handleSelect = (_, group) => {
    onGroupSelection({
      ...group,
      selected: !group.selected,
    });
  };

  const nextStep = () => {
    updatePresentationName(presentationNameField);
    next(GeneratePresentationSteps.PLACEHOLDERS_MANAGEMENT);
  };

  const atLeastOneGroupSelected = useMemo(() => {
    const foundSelected = groups.some((group) => {
      return group.selected === true;
    });

    return foundSelected;
  }, [groups]);

  const handlePresentationNameChange = (e) => {
    setPresentationNameField(e.target.value);
  };

  const handleClick = (group) => {
    onGroupSelection({
      ...group,
      selected: !group.selected,
    });
  };

  return (
    <div>
      <div>
        <Form.Label>
          {Texts.presentationName} <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          value={presentationNameField}
          onChange={handlePresentationNameChange}
        />
      </div>
      <div className="mt-2">
        <Form.Label>
          {Texts.mainTitle} <span className="text-danger">*</span>
        </Form.Label>
        <Container className="px-2">
          <Row>
            {groups.map((group) => (
              <Col md={6} className="mb-2 px-2" key={group.id}>
                <PrimitiveButton
                  className="group-item p-1"
                  onClick={handleClick}
                  value={group}
                >
                  <div className="d-flex flex-row align-items-center">
                    <div className="align-items-center">
                      <Checkbox
                        className="item-checkbox ml-2"
                        name="group-selection-check"
                        onChange={handleSelect}
                        option="selected"
                        value={group}
                      />
                    </div>
                    <div>
                      <h5 className="mb-0 text-capitalize">
                        {group.section_name}
                      </h5>
                    </div>
                  </div>
                </PrimitiveButton>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div className="d-flex justify-content-end">
        <Button
          variant="primary"
          onClick={nextStep}
          disabled={!atLeastOneGroupSelected || !presentationNameField}
        >
          {Texts.continue}
        </Button>
      </div>
    </div>
  );
}

InitialConfiguration.propTypes = {
  groups: arrayOf(
    shape({
      id: number.isRequired,
      section_name: string.isRequired,
      selected: bool.isRequired,
    }),
  ),
  next: func.isRequired,
  onGroupSelection: func.isRequired,
  presentationName: string,
  updatePresentationName: func.isRequired,
};

InitialConfiguration.defaultProps = {
  groups: [],
  presentationName: '',
};

export default InitialConfiguration;
