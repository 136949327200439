import Cookies from 'js-cookie';
import { get, includes } from 'lodash';
import appConfig from 'app/Config';
import googleSession from 'utils/GSlidesSession';

const SESSION_NAME = 'demo_library_session';
const SESSION_ACCESS = 'demo_library_access';
const SESSION_USER = 'demo_library_session_user';
const LAST_PAGE = 'last_page';

const COOKIE_DOMAIN = window.location.hostname;

const env = appConfig.get('env');
const BASE_COOKIE_CONFIG =
  env === 'qa' || env === 'prod'
    ? { sameSite: 'none', secure: true }
    : { sameSite: 'lax' };

class Session {
  /**
   * getDomainCookie
   * @param {string} name
   */
  getDomainCookie = (name) => {
    const value = Cookies.getJSON(name);
    return value || null;
  };

  isAdmin = () => {
    const accessRole = get(
      this.getDomainCookie(),
      'demo_library_access.data.roles[0]',
      '',
    );
    return accessRole === 'admin';
  };

  hasRole = (role) => {
    const userRoles = get(
      this.getDomainCookie(),
      'demo_library_access.data.roles',
      [],
    );

    return includes(userRoles, role);
  };

  /**
   * get session prop
   */
  get = (prop) => {
    const session = Cookies.getJSON(SESSION_NAME);
    return get(session, prop, null);
  };

  /**
   * save
   * @param {object} session
   */
  save = (session) => {
    const sessionCopy = { ...session };
    const secondsToExpiration = session.expires_in;
    const msToExpiration = (secondsToExpiration - 300) * 1000;

    sessionCopy.expires_at = Date.now() + msToExpiration;
    Cookies.set(SESSION_NAME, session, {
      ...BASE_COOKIE_CONFIG,
      domain: COOKIE_DOMAIN,
    });
  };

  /**
   * set access cookie for permissions
   * @param {object} session
   */
  setAccess = (access) => {
    Cookies.set(SESSION_ACCESS, access, {
      ...BASE_COOKIE_CONFIG,
      domain: COOKIE_DOMAIN,
    });
  };

  /**
   * set cookie for user information
   * @param {object} session
   */
  setUser = (user) => {
    Cookies.set(
      SESSION_USER,
      { user },
      { ...BASE_COOKIE_CONFIG, domain: COOKIE_DOMAIN },
    );
  };

  getUsername = () => {
    const username = get(
      this.getDomainCookie(SESSION_USER),
      'user.username',
      '',
    );
    return username;
  };

  getUser = () => {
    const user = get(this.getDomainCookie(SESSION_USER), 'user', {});
    return user;
  };

  removeCookie = () => {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName, { domain: COOKIE_DOMAIN });
    });
  };

  getTokenProp = (prop) => {
    const token = this.get('token');
    if (!token) return undefined;
    // as token consists of 3 components in the following order; header, body and secret
    const tokenBody = token.split('.')[1];

    if (!tokenBody) return undefined;

    const decoded = Buffer.from(tokenBody, 'base64').toString('ascii');
    const parsedToken = JSON.parse(decoded);
    return parsedToken[prop];
  };

  shouldRefreshToken = () => {
    const expiresAt = this.getTokenProp('exp');
    const shouldRefresh = !expiresAt || Date.now() >= expiresAt * 1000;
    return shouldRefresh;
  };

  /**
   * clears all user related data and redirects to login
   */
  clear = () => {
    this.removeCookie();
    googleSession.clearGCookies();
    const redirectUrl = '/';
    window.location = redirectUrl;
  };

  setLastPage = (page) => {
    sessionStorage.setItem(LAST_PAGE, page);
  };

  getLastPage = () => {
    const lastPage = sessionStorage.getItem(LAST_PAGE);
    return lastPage;
  };

  removeLastPage = () => {
    sessionStorage.removeItem(LAST_PAGE);
  };
}

const session = new Session();

export default session;
