import React, { useEffect, useState, useMemo } from 'react';
import { bool, func, number } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useRequest } from 'utils/Request';
import uuid from 'utils/uuid';
import * as arrays from 'utils/arrays';
import useModalState from 'utils/useModalState';
import { useToasts } from 'common/ActionToast';
import AreYouSure from 'common/Dialogs/AreYouSure';
import LoadingSpinner from 'common/LoadingSpinner';

import PresentationGroupForm from 'app/Forms/PresentationGroup';
import PresentationGroupingCRUD from 'app/Services/GSlides/PresentationGroupingCRUDService';
import gTemplatesService from 'app/Services/GSlides/GTemplatesCRUDService';
import PresentationGroups from 'app/Views/SlidesGrouping/components/PresentationGroups';
import AddGroupInput from 'app/Views/SlidesGrouping/components/AddGroupInput';
import Texts from './texts';

function SlidesGrouping({ templateId, onCancel, show }) {
  const [template, setTemplate] = useState([]);
  const [groups, setGroups] = useState([]);
  const toasts = useToasts();
  const updateGroupModal = useModalState();
  const deleteGroupConfirmation = useModalState();
  const getTemplate = useRequest(gTemplatesService.byId, {
    onSuccess: (responseTemplate) => {
      setTemplate(responseTemplate);
      setGroups(responseTemplate.groups);
    },
    onError: () => {},
  });

  const addHandlers = useMemo(() => {
    return {
      onSuccess: (res) => {
        setGroups(arrays.add(groups, res));
      },
      onError: () => {
        toasts.onAdd(
          uuid(),
          Texts.addGroupErrorTitle,
          Texts.addGroupErrorMessage,
        );
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  const updateHandlers = useMemo(() => {
    return {
      onSuccess: (response) => {
        setGroups(arrays.update(groups, response));
        updateGroupModal.close();
      },
      onError: () => {
        updateGroupModal.close();
        toasts.onAdd(
          uuid(),
          Texts.updateGroupErrorTitle,
          Texts.updateGroupErrorMessage,
        );
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);
  const addGroup = useRequest(PresentationGroupingCRUD.create, addHandlers);
  const updateGroup = useRequest(PresentationGroupingCRUD.edit, updateHandlers);
  const updateSlideGroup = useRequest(PresentationGroupingCRUD.moveSlide);
  const removeGroup = useRequest(PresentationGroupingCRUD.removeGroup, {
    onSuccess: setGroups,
    onError: () => {
      toasts.onAdd(
        uuid(),
        Texts.deleteFailedTitle,
        Texts.deleteFailedDescription,
      );
    },
  });

  const moveGroup = useRequest(PresentationGroupingCRUD.moveGroup, {
    onSuccess: setGroups,
    onError: () => {
      toasts.onAdd(
        uuid(),
        Texts.groupMoveFailedTitle,
        Texts.groupMoveFailedDescription,
      );
      getTemplate.start(templateId);
    },
  });

  const handleDeleteGroup = (groupToDelete) => {
    const groupId = groupToDelete.id;
    removeGroup.start(groupId);
    deleteGroupConfirmation.close();
  };

  const handleSlidesMoved = (updatedGroup) => {
    updateSlideGroup.start({
      ...updatedGroup,
      presentation_templates_id: template.id,
    });
  };

  const handleGroupMoved = (updatedGroup) => {
    moveGroup.start({
      ...updatedGroup,
      presentation_templates_id: template.id,
    });
  };

  useEffect(() => {
    getTemplate.start(templateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {toasts.elements}

      {updateGroupModal.show && (
        <PresentationGroupForm
          group={updateGroupModal.value}
          onCancel={updateGroupModal.close}
          onSubmit={updateGroup.start}
          show={updateGroupModal.show}
        />
      )}
      {deleteGroupConfirmation.show && (
        <AreYouSure
          message={Texts.deleteGroupDescription}
          onAccept={handleDeleteGroup}
          onCancel={deleteGroupConfirmation.close}
          show={deleteGroupConfirmation.show}
          title={Texts.deleteGroupTitle}
          value={deleteGroupConfirmation.value}
        />
      )}

      <Modal size="xl" show={show} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Group Slides</Modal.Title>
        </Modal.Header>
        {getTemplate.inProcess && !getTemplate.error && (
          <LoadingSpinner message="Loading Slides" />
        )}

        {getTemplate.response && !getTemplate.error && (
          <Modal.Body>
            <AddGroupInput templateId={templateId} onAdd={addGroup.start} />
            <PresentationGroups
              groups={groups}
              onDeleteClick={deleteGroupConfirmation.open}
              onGroupMoved={handleGroupMoved}
              onSlidesMoved={handleSlidesMoved}
              onUpdateClick={updateGroupModal.open}
              slides={template.placeholder_schema?.slides}
            />
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
}

SlidesGrouping.propTypes = {
  onCancel: func.isRequired,
  show: bool,
  templateId: number.isRequired,
};

SlidesGrouping.defaultProps = {
  show: false,
};

export default SlidesGrouping;
