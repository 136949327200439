import React from 'react';
import { func } from 'prop-types';
import appConfig from 'app/Config';
import gslidesSession from 'utils/GSlidesSession';
import safeInvoke from 'utils/safeInvoke';
import GoogleLogin from './components/GoogleLogin';
import gclient from '../GoogleClient';

const G_CLIENT_ID = appConfig.get('gclientId');
const COOKIE_POLICY = 'single_host_origin';
const SCOPES = gclient.scopes();

function GLogin({ onLoginSuccess, onLoginError }) {
  const handleLoginSuccess = (res) => {
    const token = res.tokenObj;
    gslidesSession.setGUser({ name: res.profileObj.name });
    gslidesSession.setGUserToken(token);
    gclient.loadClients(token.access_token, () => safeInvoke(onLoginSuccess));
  };

  const handleLoginFailure = () => {
    safeInvoke(onLoginError);
  };

  return (
    <GoogleLogin
      clientId={G_CLIENT_ID}
      buttonText="Login"
      onSuccess={handleLoginSuccess}
      onFailure={handleLoginFailure}
      cookiePolicy={COOKIE_POLICY}
      scope={SCOPES}
    />
  );
}

export default GLogin;

GLogin.propTypes = {
  onLoginError: func,
  onLoginSuccess: func,
};

GLogin.defaultProps = {
  onLoginError: undefined,
  onLoginSuccess: undefined,
};
