import http from 'utils/Http';
import CRUDService from './CRUDService';

class InteractiveCRUD extends CRUDService {
  constructor() {
    super({
      mapEntityFrom: (data) => data,
      mapListFrom: (data) => data,
      mapTo: (data) => data,
      buildUrl: (suffix) => `api/v1/interactives${suffix ? `${suffix}` : ''}`,
    });
  }

  searchPublications = (id) => async (query, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: `api/v1/interactives/${id}/published_interactives${query}`,
      });

      const mappedList = this.mapListFrom(response.data);

      responseHandlers.onSuccess({
        data: mappedList,
        lastPage: response.lastPage,
        page: response.page,
        perPage: response.perPage,
        total: response.total,
        count: response.count,
      });
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new InteractiveCRUD();
