import React from 'react';
import { bool, oneOf, string } from 'prop-types';
import {
  CheckCircleFill,
  ExclamationTriangleFill,
  ExclamationCircleFill,
} from 'react-bootstrap-icons';
import cx from 'classnames';

export const TOUR_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

const getStatusIcon = (status) => {
  switch (status) {
    case TOUR_STATUS.SUCCESS:
      return <CheckCircleFill className="text-success" />;

    case TOUR_STATUS.ERROR:
      return <ExclamationTriangleFill className="text-danger" />;
    default:
      return <ExclamationCircleFill className="text-warning" />;
  }
};

const getStatusMessage = (status) => {
  switch (status) {
    case TOUR_STATUS.SUCCESS:
      return 'Latest';

    case TOUR_STATUS.ERROR:
      return 'Something went wrong while saving.';
    default:
      return 'Saving...';
  }
};

function TourStatus({ className, status, isSaving }) {
  return (
    <div
      className={cx(
        'd-flex justify-content-center align-items-center',
        className,
      )}
    >
      {getStatusIcon(!isSaving && status)}
      <span className="pl-2"> {getStatusMessage(!isSaving && status)}</span>
    </div>
  );
}

TourStatus.propTypes = {
  className: string.isRequired,
  isSaving: bool.isRequired,
  status: oneOf(['success', 'error']).isRequired,
};

export default TourStatus;
