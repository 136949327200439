import React from 'react';
import Card from 'react-bootstrap/Card';
import { ThreeDots as ThreeDotsIcon } from 'react-bootstrap-icons';
import { get } from 'lodash';
import DataTable from 'common/DataTable';
import SearchInput from 'common/Inputs/Search';
import FloatingMenu, {
  FloatinMenuOption,
  FloatingMenuState,
} from 'common/FloatingMenu';
import ManagementPropTypes from './propTypes';
import './styles.scss';

function ManagementTable({
  viewActions,
  rows,
  searchState,
  rowActions,
  onCellClicked,
  searchPlaceholder,
}) {
  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <div>
              <SearchInput
                placeholder={searchPlaceholder}
                name="dataSearchControl"
                onChange={searchState.change}
                value={searchState.query.qs}
              />
            </div>
          </div>
          <div>{viewActions}</div>
        </div>
        <div>
          <DataTable
            onLimitChange={searchState.changeLimit}
            onSortChange={searchState.changeSort}
            onPageChange={searchState.changePage}
            onQueryChange={searchState.change}
            onReload={searchState.reload}
            total={get(searchState, 'query.result.total', 0)}
            page={searchState.query.page}
            limit={searchState.query.limit}
            isFetching={searchState.query.isLoading}
            hasError={searchState.query.error}
          >
            <thead>
              <tr>
                {rows.map((row) => (
                  <row.headerComponent
                    key={row.headerValue}
                    onChange={searchState.changeSort}
                    current={searchState.query.sort}
                    value={row.headerValue}
                  >
                    {row.headerLabel}
                  </row.headerComponent>
                ))}
              </tr>
            </thead>
            {true && ( // !isLoading && (
              <tbody>
                {get(searchState, 'latestResponse.data', []).map((item) => (
                  <tr key={item.id}>
                    {rows.map((row) => (
                      <row.cellComponent
                        key={row.key}
                        onClick={onCellClicked}
                        value={item}
                      >
                        {get(item, row.key)}
                      </row.cellComponent>
                    ))}
                    {rowActions.length && (
                      <td>
                        <FloatingMenuState>
                          {({ show, open }) => (
                            <>
                              <ThreeDotsIcon
                                onClick={open}
                                className="cursor-pointer"
                              />
                              {show && (
                                <FloatingMenu id="demo-floating-menu">
                                  <Card className="py-1">
                                    {rowActions.map((action) => (
                                      <FloatinMenuOption
                                        key={action.label}
                                        onClick={action.handler}
                                        value={item}
                                      >
                                        {action.label}
                                      </FloatinMenuOption>
                                    ))}
                                  </Card>
                                </FloatingMenu>
                              )}
                            </>
                          )}
                        </FloatingMenuState>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </DataTable>
        </div>
      </div>
    </div>
  );
}

ManagementTable.propTypes = ManagementPropTypes;

export default ManagementTable;
