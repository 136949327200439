import { useCallback } from 'react';
import debounce from 'debounce-promise';

function useAsyncLoad(searchService, { limit } = {}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedService = useCallback(
    debounce((inputValue) => {
      return limit
        ? searchService(`?q=${inputValue}&limit=${limit}`)
        : searchService(inputValue);
    }, 500),
    [],
  );
  return debouncedService;
}

export default useAsyncLoad;
