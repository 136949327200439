/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from 'react';
import Shell from 'app/Shells/Management';
import Card from 'react-bootstrap/Card';
import { useHistory } from 'react-router-dom';
import { ThreeDots as ThreeDotsIcon } from 'react-bootstrap-icons';
import { get } from 'lodash';
import routerMap from 'app/RouterMap';
import interactiveService from 'app/Services/InteractiveCRUDService';
import useSearchState from 'utils/useSearchState';
import useModalState from 'utils/useModalState';
import { to_MMDDYYYYHHMMA } from 'utils/dates';
import InteractiveForm from 'app/Forms/Interactive';
import DataTable from 'common/DataTable';
import IconButton from 'common/Buttons/Icon';
import SortableHeader from 'common/DataTable/SortableHeader';
import SearchInput from 'common/Inputs/Search';
import { useToasts } from 'common/ActionToast';
// import OverlayLink from 'common/Overlay/OverlayLink';
import FloatingMenu, {
  FloatinMenuOption,
  FloatingMenuState,
} from 'common/FloatingMenu';
import { AddI } from 'common/icons';
import { useRequest } from 'utils/Request';
import { canBeArchived, canBeUnarchived } from 'utils/business/demo';
import Texts from './texts';
import './styles.scss';

function InteractivePage() {
  const interactiveModal = useModalState();
  const toasts = useToasts();
  const history = useHistory();
  const search = useSearchState(interactiveService.search, ['q'], undefined, {
    sort: [{ field: 'name', order: 'asc' }],
  });
  const addService = useRequest(interactiveService.create, {
    onSuccess: (item) => {
      interactiveModal.close();
      search.reload();
      toasts.onAdd(item.id, Texts.createdTitle, Texts.createdDescription(item));
    },
    onError: () => {},
  });
  const updateService = useRequest(interactiveService.edit, {
    onSuccess: (item) => {
      interactiveModal.close();
      search.reload();
      toasts.onAdd(item.id, Texts.updatedTitle, Texts.updatedDescription(item));
    },
    onError: () => {},
  });
  const archiveService = useRequest(interactiveService.archive, {
    onSuccess: () => {
      search.reload();
    },
    onError: () => {},
  });
  const unarchiveService = useRequest(interactiveService.unarchive, {
    onSuccess: () => {
      search.reload();
    },
    onError: () => {},
  });

  const handleRowClick = (interactive) => {
    history.push(
      routerMap.interactiveDetail.getPath(interactive.id, interactive.name),
    );
  };

  const handleAddInteractive = (form, formikBag) => {
    const interactiveToAdd = {
      id: form.id,
      name: form.name,
      prototype_url: form.prototype_url,
    };
    const operation = form.id ? updateService : addService;
    operation.start(interactiveToAdd, formikBag);
  };

  return (
    <Shell page="Interactives">
      <div>
        {toasts.elements}
        {interactiveModal.show && (
          <InteractiveForm
            onCancel={interactiveModal.close}
            onSuccess={interactiveModal.close}
            onSubmit={handleAddInteractive}
            show={interactiveModal.show}
            interactive={interactiveModal.value}
          />
        )}
        <div id="interactive">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <div>
                <SearchInput
                  placeholder="Search for interactives"
                  name="interactiveSearch"
                  onChange={search.change}
                  value={search.query.qs}
                />
              </div>
            </div>
            <div>
              <IconButton onClick={interactiveModal.open} Icon={AddI} />
            </div>
          </div>
          <div>
            <DataTable
              onLimitChange={search.changeLimit}
              onPageChange={search.changePage}
              onSortChange={search.changeSort}
              onReload={search.reload}
              total={get(search, 'query.result.total', 0)}
              page={search.query.page}
              limit={search.query.limit}
              isFetching={search.query.isLoading}
              hasError={search.query.error}
            >
              <thead>
                <tr>
                  <SortableHeader
                    onChange={search.changeSort}
                    value="name"
                    current={search.query.sort}
                  >
                    Interactive
                  </SortableHeader>
                  <SortableHeader
                    onChange={search.changeSort}
                    value="status"
                    current={search.query.sort}
                  >
                    Status
                  </SortableHeader>
                  <SortableHeader
                    onChange={search.changeSort}
                    value="users.username"
                    current={search.query.sort}
                  >
                    Added By
                  </SortableHeader>
                  <SortableHeader
                    onChange={search.changeSort}
                    value="interactives.created_at"
                    current={search.query.sort}
                  >
                    Date Added
                  </SortableHeader>
                  <SortableHeader
                    onChange={search.changeSort}
                    value="interactives.updated_at"
                    current={search.query.sort}
                  >
                    Date Updated
                  </SortableHeader>
                </tr>
              </thead>
              {!search.query.isLoading && (
                <tbody>
                  {get(search, 'latestResponse.data').map((item) => (
                    <tr key={item.id}>
                      <td
                        className="bold"
                        onClick={() => handleRowClick(item)}
                        role="presentation"
                      >
                        <span className="link">{item.name}</span>
                      </td>
                      <td className="status">
                        <span
                          className={`status-indicator status-${item.status.toLowerCase()}`}
                        />
                        {item.status}
                      </td>
                      <td>{item.user.username}</td>
                      <td>{to_MMDDYYYYHHMMA(item.created_at)}</td>
                      <td>{to_MMDDYYYYHHMMA(item.updated_at)}</td>
                      <td>
                        <FloatingMenuState>
                          {({ show, open }) => (
                            <>
                              <ThreeDotsIcon
                                onClick={open}
                                className="cursor-pointer"
                              />
                              {show && (
                                <FloatingMenu id="demo-floating-menu">
                                  <Card className="py-1">
                                    <FloatinMenuOption
                                      onClick={interactiveModal.open}
                                      value={item}
                                    >
                                      Edit
                                    </FloatinMenuOption>
                                    {canBeArchived(item) && (
                                      <FloatinMenuOption
                                        onClick={archiveService.start}
                                        value={item.id}
                                      >
                                        Archive
                                      </FloatinMenuOption>
                                    )}
                                    {canBeUnarchived(item) && (
                                      <FloatinMenuOption
                                        onClick={unarchiveService.start}
                                        value={item.id}
                                      >
                                        Unarchive
                                      </FloatinMenuOption>
                                    )}
                                  </Card>
                                </FloatingMenu>
                              )}
                            </>
                          )}
                        </FloatingMenuState>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </DataTable>
          </div>
        </div>
      </div>
    </Shell>
  );
}

export default InteractivePage;
