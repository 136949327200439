const Texts = {
  title: 'Presentations',
  settingsToastTitle: 'Slides Settings',
  settingsToastError: `Couldn't update settings`,
  settingsToastSuccess: 'Updated settings',
  importToastTitle: 'Template Import',
  importToastSuccess: (name) => `Imported: "${name}" template`,
  removeToastTitle: 'Template Deleted',
  removeToastSuccess: `Successfully removed`,
};

export default Texts;
