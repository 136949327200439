import { get } from 'lodash';
import CRUDService from 'app/Services/CRUDService';
import http from 'utils/Http';

class GSettingsCRUD extends CRUDService {
  constructor() {
    super({
      mapEntityFrom: (data = {}) => ({
        id: data.id,
        templates_folder: {
          id: data.templates_folder_id,
          name: data.templates_folder_name,
        },
        presentations_folder: {
          id: data.presentations_folder_id,
          name: data.presentations_folder_name,
        },
      }),
      mapListFrom: (data) => data,
      mapTo: (data) => ({
        id: get(data, 'id', null),
        templates_folder_id: get(data, 'templates_folder.id'),
        templates_folder_name: get(data, 'templates_folder.name'),
        presentations_folder_id: get(data, 'presentations_folder.id'),
        presentations_folder_name: get(data, 'presentations_folder.name'),
      }),
      buildUrl: (suffix) =>
        `api/v1/slides_configurations${suffix ? `${suffix}` : ''}`,
    });
  }

  load = async (_, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: this.buildUrl(),
      });
      const config = response.data[0];
      responseHandlers.onSuccess(this.mapEntityFrom(config));
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default new GSettingsCRUD();
