import React from 'react';
import { Formik } from 'formik';
import { number, string, bool, func, shape } from 'prop-types';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import SubmitErrorMessage from 'common/Message/SubmitError';
import Texts from './texts';
import './style.scss';

const validationSchema = yup.object({
  section_name: yup
    .string()
    .required(Texts.requiredError(Texts.groupNameLabel)),
});

function PresentationGroupForm({ group, onCancel, onSubmit, show }) {
  return (
    <Modal size="md" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {group.id ? Texts.editTitle(group.section_name) : Texts.createTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={group}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          errors,
          isSubmitting,
          touched,
          values,
        }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>{Texts.groupNameLabel}</Form.Label>
                      <Form.Control
                        name="section_name"
                        type="text"
                        onChange={handleChange}
                        value={values.section_name}
                        isInvalid={touched.section_name && errors.section_name}
                      />
                      {touched.section_name && errors.section_name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.section_name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Modal.Body>
              {!isSubmitting && errors.internalServerError && (
                <SubmitErrorMessage>
                  {errors.internalServerError}
                </SubmitErrorMessage>
              )}
              <Modal.Footer>
                <AcceptCancelButton
                  isProcessing={isSubmitting}
                  acceptLabel={group.id ? Texts.editLabel : Texts.acceptLabel}
                  onCancel={onCancel}
                  onAccept={handleSubmit}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

PresentationGroupForm.propTypes = {
  group: shape({
    id: number,
    section_name: string,
  }),
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  show: bool,
};

PresentationGroupForm.defaultProps = {
  show: false,
  group: {
    id: undefined,
    name: '',
  },
};

export default PresentationGroupForm;
