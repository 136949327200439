import React, { useState, useEffect, useMemo } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Checkbox from 'common/Inputs/Checkboxes/Checkbox';
import Button from 'common/Buttons/Button';
import Slide from 'app/Views/Slide';
import { GeneratePresentationSteps } from 'app/Views/SlidesNavigation/constants';

import Texts from './texts';
import './styles.scss';

const initSelectedSlides = (slides) =>
  Object.keys(slides).reduce((acc, slideId) => {
    return {
      ...acc,
      [slideId]: false,
    };
  }, {});

function SlidesSelection({ groups, next, slides, updateGenData }) {
  const [selectedSlides, setSelectedSlides] = useState(
    initSelectedSlides(slides),
  );
  const isAtLeastOneSlideSelected = useMemo(() => {
    return Object.values(selectedSlides).some((val) => val === true);
  }, [selectedSlides]);

  const handleSlideSelect = (_, updatedSelectedSlides) => {
    setSelectedSlides(updatedSelectedSlides);
  };

  const toggleGroupSelectedSlides = (_, group) => {
    const newSelectedSlides = { ...selectedSlides };
    group.slideIds.forEach((slideId) => {
      newSelectedSlides[slideId] = group.checked;
    });

    setSelectedSlides(newSelectedSlides);
  };

  const isGroupSelected = (group) => {
    const foundUncheck = group.slide_list.some((slideId) => {
      return selectedSlides[slideId] === false;
    });

    return !foundUncheck;
  };

  const handleNextStep = () => {
    updateGenData({
      selectedSlides,
    });
    next(GeneratePresentationSteps.GROUP_ORDER);
  };

  const goBack = () => {
    next(GeneratePresentationSteps.SLIDE_EDIT);
  };

  useEffect(() => {
    setSelectedSlides(initSelectedSlides(slides));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);
  return (
    <div className="slides-grid-select-view">
      <h5 className="mb-1">{Texts.title}</h5>
      <div>
        <Container className="px-0 groups">
          {groups.map((group) => (
            <Card
              key={group.id}
              className="my-2 mx-2 px-1 py-1 group-container"
            >
              <Card.Body className="p-0 px-3 py-1 group-body">
                <Card.Title className="mb-0 text-capitalize cursor-pointer d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="mr-2">{group.section_name}</div>
                    <div>
                      {group.slide_list.length > 0 && (
                        <Checkbox
                          name="group-header-check"
                          onChange={toggleGroupSelectedSlides}
                          option="checked"
                          value={{
                            slideIds: group.slide_list,
                            checked: isGroupSelected(group),
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Card.Title>
                <Row className="group-slides mt-3">
                  {group.slide_list.map(
                    (slideId) =>
                      slides[slideId] && (
                        <Col md={3} className="mb-2 px-1" key={slideId}>
                          <div>
                            <Checkbox
                              className="slide-group-check"
                              checked={group.selected}
                              name="slide-group-selection-check"
                              onChange={handleSlideSelect}
                              option={slideId}
                              value={selectedSlides}
                            />
                            <Slide
                              id={slideId}
                              thumbnailUrl={slides[slideId].imageURL}
                            />
                          </div>
                        </Col>
                      ),
                  )}
                </Row>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </div>
      <div className="d-flex justify-content-end">
        <Button size="md" variant="secondary" onClick={goBack} className="mr-2">
          {Texts.return}
        </Button>
        <Button
          variant="primary"
          onClick={handleNextStep}
          disabled={!isAtLeastOneSlideSelected}
        >
          {Texts.continue}
        </Button>
      </div>
    </div>
  );
}

SlidesSelection.propTypes = {
  groups: arrayOf(
    shape({
      id: number.isRequired,
      section_name: string.isRequired,
      slide_list: arrayOf(string).isRequired,
    }),
  ),
  next: func.isRequired,
  slides: shape({}),
  updateGenData: func.isRequired,
};

SlidesSelection.defaultProps = {
  groups: [],
  slides: {},
};

export default SlidesSelection;
