const Texts = {
  createTitle: 'Add Group',
  groupNameLabel: 'Group Name',
  editLabel: 'Save',
  cancelLabel: 'Cancel',
  inProcess: 'Saving...',
  requiredError: (name) => `${name} is required`,
  editTitle: (name) => `Edit Group - ${name}`,
};

export default Texts;
