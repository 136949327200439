import http from 'utils/Http';
import { handleFormErrors } from './utils';

const assignIfValue = (handler) =>
  handler ||
  ((data) => {
    return data;
  });

const handleError = (error, errorHandler, formikBag) => {
  if (errorHandler?.onError) {
    errorHandler.onError(error);
  } else if (formikBag) {
    handleFormErrors(error, formikBag);
  }
};

class CRUDService {
  constructor({ mapEntityFrom, mapListFrom, mapTo, buildUrl }) {
    this.mapEntityFrom = assignIfValue(mapEntityFrom);
    this.mapListFrom = assignIfValue(mapListFrom);
    this.buildUrl = buildUrl;
    this.mapTo = assignIfValue(mapTo);
  }

  create = async (demoForm, responseHandlers, formikBag) => {
    try {
      const { data } = await http.post({
        url: this.buildUrl(),
        body: this.mapTo(demoForm),
      });
      responseHandlers.onSuccess(this.mapEntityFrom(data));
    } catch (error) {
      handleError(error, responseHandlers.onError, formikBag);
    } finally {
      formikBag?.setSubmitting(false);
    }
  };

  edit = async (form, responseHandlers, formikBag) => {
    try {
      const { data } = await http.put({
        url: this.buildUrl(`/${form.id}`),
        body: this.mapTo(form),
      });
      responseHandlers.onSuccess(this.mapEntityFrom(data));
    } catch (error) {
      handleError(error, responseHandlers.onError, formikBag);
    } finally {
      formikBag?.setSubmitting(false);
    }
  };

  save = async (form, responseHandlers, formikBag) => {
    try {
      const { data } = await http.post({
        url: this.buildUrl('/save'),
        body: this.mapTo(form),
      });
      responseHandlers.onSuccess(this.mapEntityFrom(data));
    } catch (error) {
      handleFormErrors(error, formikBag);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  delete = async (id, responseHandlers) => {
    try {
      await http.delete({
        url: this.buildUrl(`/${id}`),
      });
      responseHandlers.onSuccess();
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  all = async (query, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: this.buildUrl(query),
      });

      const mappedList = this.mapListFrom(response.data);

      responseHandlers.onSuccess({
        data: mappedList,
        lastPage: response.lastPage,
        page: response.page,
        perPage: response.perPage,
        total: response.total,
        count: response.count,
      });
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  search = async (query, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: this.buildUrl(`/search${query}`),
      });

      const mappedList = this.mapListFrom(response.data);

      responseHandlers.onSuccess({
        data: mappedList,
        lastPage: response.lastPage,
        page: response.page,
        perPage: response.perPage,
        total: response.total,
        count: response.count,
      });
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  byId = async (id, responseHandlers) => {
    try {
      const { data: response } = await http.get({
        url: this.buildUrl(`/${id}`),
      });

      responseHandlers.onSuccess(this.mapEntityFrom(response));
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  archive = async (id, responseHandlers) => {
    try {
      const res = await http.put({
        url: this.buildUrl(`/${id}/archive`),
      });
      responseHandlers.onSuccess(this.mapEntityFrom(res.data));
    } catch (error) {
      responseHandlers.onError(error);
    }
  };

  unarchive = async (id, responseHandlers) => {
    try {
      const res = await http.put({
        url: this.buildUrl(`/${id}/unarchive/`),
      });
      responseHandlers.onSuccess(this.mapEntityFrom(res.data));
    } catch (error) {
      responseHandlers.onError(error);
    }
  };
}

export default CRUDService;
