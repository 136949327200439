import { useState, useEffect } from 'react';

function useFilterLoader(service, mapper) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const load = (filterId) => {
    setIsLoading(true);
    service(filterId, {
      onSuccess: (data) => {
        setOptions(mapper(data));
        setIsLoading(false);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    options,
    isLoading,
    load,
  };
}

export default useFilterLoader;
