import loadScript from 'utils/loadScript';
import gsession from 'utils/GSlidesSession';

import { SCRIPT_ID, SCRIPT_SRC } from './constants';

const GoogleClient = () => {
  const scopes = [
    'https://www.googleapis.com/auth/presentations',
    'https://www.googleapis.com/auth/drive',
    'https://www.googleapis.com/auth/drive.appdata',
    'https://www.googleapis.com/auth/drive.file',
    'https://www.googleapis.com/auth/drive.readonly',
  ];

  const loadClients = (token, cb) => {
    window.gapi.client.setToken({ access_token: token });
    if (!window.gapi.client.drive) {
      window.gapi.client.load('drive', 'v3', () => {
        cb();
      });
    }
  };

  const init = (cb) => {
    loadScript({
      id: SCRIPT_ID,
      src: SCRIPT_SRC,
      onLoad: () => {
        if (window.gapi.auth && window.gapi.client) return;
        window.gapi.load('auth2:client', () => {
          // check on cookie
          if (gsession.isSignedIn()) {
            loadClients(gsession.getAccessToken(), cb);
          } else {
            cb();
          }
        });
      },
    });
  };

  return {
    init,
    loadClients,
    drive: () => window.gapi.client.drive,
    scopes: () => scopes.join(' '),
  };
};

export default GoogleClient();
