/* eslint-disable no-unused-vars */
import { get } from 'lodash';
import gclient from 'utils/Google/GoogleClient';

const GetFolderByName = async (folderName, handlers) => {
  try {
    const res = await gclient.drive().files.list({
      q: `mimeType = 'application/vnd.google-apps.folder' and name  contains '${folderName}'`,
      space: 'drive',
      pageSize: 100,
      fields: 'files (name, id)',
    });
    const folders = get(res, 'result.files', []).map((item) => ({
      label: item.name,
      value: item,
    }));
    if (!get(handlers, 'onSuccess')) return folders;
    return handlers.onSuccess(folders);
  } catch (error) {
    if (!get(handlers, 'onError')) return error;
    return handlers.onError(error);
  }
};

export default GetFolderByName;
