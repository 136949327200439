/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import Slide from 'app/Views/Slide';

import './styles.scss';

const isSingleSelect = (e) => {
  const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
  return isUsingWindows ? e.ctrlKey : e.metaKey;
};

const isMultiSelect = (e) => e.shiftKey;

function DraggableSlide({
  groupId,
  index,
  isSelected,
  onSelect,
  multiSelectTo,
  selectedSlidesCount,
  slideId,
  thumbnailUrl,
  toggleInGroup,
}) {
  const selectSlide = (e) => {
    if (isSingleSelect(e)) {
      toggleInGroup(groupId, slideId);
      return;
    }

    if (isMultiSelect(e)) {
      multiSelectTo(groupId, slideId);
      return;
    }

    onSelect(slideId);
  };

  const handleClick = (e) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.button !== 0) {
      return;
    }

    e.preventDefault();

    selectSlide(e);
  };

  return (
    <Draggable key={slideId} draggableId={slideId} index={index}>
      {(provided, snapshot) => (
        <div
          md={4}
          className={`slide-draggable p-2 ${isSelected ? 'selected' : ''}`}
          key={slideId}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={handleClick}
        >
          <Slide id={slideId} thumbnailUrl={thumbnailUrl} />
          {snapshot.isDragging && selectedSlidesCount > 1 && (
            <div className="selected-slides-count">{selectedSlidesCount}</div>
          )}
        </div>
      )}
    </Draggable>
  );
}

DraggableSlide.propTypes = {
  groupId: number.isRequired,
  index: number.isRequired,
  isSelected: bool,
  multiSelectTo: func.isRequired,
  onSelect: func.isRequired,
  selectedSlidesCount: number.isRequired,
  slideId: string.isRequired,
  thumbnailUrl: string.isRequired,
  toggleInGroup: func.isRequired,
};

DraggableSlide.defaultProps = {
  isSelected: false,
};

export default DraggableSlide;
