import React from 'react';
import Image from 'react-bootstrap/Image';
import { useHistory, useLocation } from 'react-router-dom';
import session from 'utils/Session';
import routerMap from 'app/RouterMap';
import logo from 'logo.svg';
import {
  DashI,
  DashIActive,
  InteractiveI,
  LibraryI,
  LibraryIActive,
  ReportI,
  SettingsI,
  SettingsIActive,
  GSlidesI,
  GSlidesIActive,
} from 'common/icons';
import NavItem from '../NavItem';
import Texts from './texts';
import './styles.scss';

function Sidebar() {
  const location = useLocation();
  const history = useHistory();
  const isAdmin = session.isAdmin();

  const redirect = (newLocation) => {
    history.push(newLocation);
  };
  const activeRoot = `/${location.pathname.split('/')[1]}`;

  return (
    <div>
      <div className="pt-1 pb-1 demo-library-app-side-logo-wrapper">
        <Image src={logo} className="demo-library-app-side-logo" />
        <div className="mt-1 ml-1 subtext demo-library-app-side-title">
          {Texts.title}
        </div>
      </div>
      <div className="d-flex flex-column demo-library-app-side-menu my-2 pt-3">
        <NavItem
          activeNav={activeRoot}
          icon={DashI}
          activeIcon={DashIActive}
          label={Texts.dash}
          onClick={redirect}
          value={routerMap.dash.getPath()}
        />
        <NavItem
          activeNav={activeRoot}
          icon={LibraryI}
          activeIcon={LibraryIActive}
          label={Texts.demoLibrary}
          onClick={redirect}
          value={routerMap.library.getPath()}
        />
        <NavItem
          activeNav={activeRoot}
          icon={InteractiveI}
          activeIcon={InteractiveI}
          label={Texts.interactive}
          onClick={redirect}
          value={routerMap.interactive.getPath()}
        />
        <NavItem
          activeNav={activeRoot}
          icon={ReportI}
          activeIcon={ReportI}
          label={Texts.reports}
          onClick={redirect}
          value={routerMap.reports.getPath()}
        />
        <NavItem
          activeNav={activeRoot}
          icon={GSlidesI}
          activeIcon={GSlidesIActive}
          label={Texts.gslides}
          onClick={redirect}
          value={routerMap.gslides.getPath()}
        />
        {isAdmin && (
          <NavItem
            activeNav={activeRoot}
            icon={SettingsI}
            activeIcon={SettingsIActive}
            label={Texts.settings}
            onClick={redirect}
            value={routerMap.settings.getPath()}
          />
        )}
      </div>
    </div>
  );
}

export default Sidebar;
