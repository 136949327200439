import React from 'react';
import { node, number } from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import './styles.scss';

function DroppableSpace({ children, groupId }) {
  return (
    <Droppable
      droppableId={`${groupId}`}
      key={`droppable-${groupId}`}
      type="slide"
    >
      {(provided) => (
        <div
          className="droppable-group-space mt-0 mb-0 px-2 pt-2 pb-0"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

DroppableSpace.propTypes = {
  children: node.isRequired,
  groupId: number.isRequired,
};

export default DroppableSpace;
