/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { bool, func, shape, string, number } from 'prop-types';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import useAsyncLoad from 'utils/useAsyncLoad';
import AuthModal from 'utils/Google/AuthModal';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import SubmitErrorMessage from 'common/Message/SubmitError';
import ErrorMessage from 'common/Message/ErrorMessage';
import Select from 'common/Inputs/AsyncSelect';
import GetFolderByName from 'app/Services/Google/GetFolderByName';

import Texts from './texts';

const validationSchema = yup.object({
  templates_folder: yup
    .string()
    .required(Texts.requiredError(Texts.templatesLabel)),
  presentations_folder: yup
    .string()
    .required(Texts.requiredError(Texts.presentationsLabel)),
});

function GSlidesConfig({ onCancel, onSubmit, show, values }) {
  const searchTemplateFolder = useAsyncLoad(GetFolderByName);
  const searchPresentationFolder = useAsyncLoad(GetFolderByName);
  const defaultTOptions = useMemo(() => {
    return values.id
      ? [
          {
            label: values.templates_folder.name,
            value: values.templates_folder,
          },
        ]
      : [];
  }, []);
  const defaultPOptions = useMemo(() => {
    return values.id
      ? [
          {
            label: values.presentations_folder.name,
            value: values.presentations_folder,
          },
        ]
      : [];
  }, []);
  return (
    <AuthModal onCancel={onCancel}>
      <Modal size="md" show={show} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{Texts.editTitle}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            setFieldValue,
            values: v,
            errors,
            isSubmitting,
          }) => {
            return (
              <Form noValidate>
                <>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Label htmlFor="templates_folder">
                        {Texts.templatesLabel}
                      </Form.Label>
                      <Select
                        name="templates_folder"
                        placeholder={Texts.selectPlaceholder}
                        defaultOptions={defaultTOptions}
                        value={
                          v.templates_folder
                            ? {
                                value: v.templates_folder,
                                label: v.templates_folder.name,
                              }
                            : null
                        }
                        loadOptions={searchTemplateFolder}
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="templates_folder" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="presentations_folder">
                        {Texts.presentationsLabel}
                      </Form.Label>
                      <Select
                        name="presentations_folder"
                        placeholder={Texts.selectPlaceholder}
                        defaultOptions={defaultPOptions}
                        value={
                          v.presentations_folder
                            ? {
                                value: v.presentations_folder,
                                label: v.presentations_folder.name,
                              }
                            : null
                        }
                        loadOptions={searchPresentationFolder}
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="presentations_folder" />
                    </Form.Group>
                  </Modal.Body>
                  {!isSubmitting && errors.internalServerError && (
                    <SubmitErrorMessage>
                      {errors.internalServerError}
                    </SubmitErrorMessage>
                  )}
                  <Modal.Footer>
                    <AcceptCancelButton
                      isProcessing={isSubmitting}
                      acceptLabel={Texts.acceptLabel}
                      onCancel={onCancel}
                      onAccept={handleSubmit}
                    />
                  </Modal.Footer>
                </>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </AuthModal>
  );
}

GSlidesConfig.propTypes = {
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  show: bool,
  values: shape({
    id: number,
    templates_folder: shape({
      id: string,
      name: string,
    }),
    presentations_folder: shape({
      id: string,
      name: string,
    }),
  }),
};

GSlidesConfig.defaultProps = {
  show: false,
  values: {
    id: undefined,
    templates_folder: undefined,
    presentations_folder: undefined,
  },
};

export default GSlidesConfig;
