const Texts = {
  downloadTitle: 'Download Placeholders File',
  allPlaceholders: 'All Placeholders',
  selectedTopics: 'Placeholders for Selected Topics',
  uploadTitle: 'Upload the Content for the Presentation',
  continue: 'Continue',
  return: 'Go Back',
};

export default Texts;
