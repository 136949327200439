/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { func, string } from 'prop-types';
import { isFunction } from 'lodash';
import Image from 'react-bootstrap/Image';

import './styles.scss';

function Slide({ id, onSlideClick, thumbnailUrl }) {
  const handleClick = () => {
    if (isFunction(onSlideClick)) {
      onSlideClick(id);
    }
  };

  return (
    <div>
      <Image src={thumbnailUrl} thumbnail onClick={handleClick} />
    </div>
  );
}

Slide.propTypes = {
  id: string.isRequired,
  onSlideClick: func,
  thumbnailUrl: string.isRequired,
};

Slide.defaultProps = {
  onSlideClick: undefined,
};

export default Slide;
