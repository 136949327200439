const defaultGlobalEnvs = {
  domain: process.env.REACT_APP_DOMAIN,
  experienceURL: process.env.REACT_APP_EXPERIENCE_URL,
  env: process.env.REACT_APP_ENV,
  gclientId: process.env.REACT_APP_AUREA_GSLIDE_CLIENT_ID,
  gAPIKey: process.env.REACT_APP_AUREA_GSLIDE_API_KEY,
  gAppId: process.env.REACT_APP_AUREA_GSLIDE_APP_ID,
};

class Config {
  constructor() {
    this.envs = defaultGlobalEnvs;
  }

  get = (key) => {
    return this.envs[key];
  };
}

export default new Config();
