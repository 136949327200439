import React, { useRef, useState } from 'react';
import { get } from 'lodash';
import Image from 'common/Image';
import cx from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import useQueryString from 'utils/useQueryString';
import useModalState from 'utils/useMultipleModalState';
import useWindowResize from 'utils/useWindowResize';
import routerMap from 'app/RouterMap';
import AreYouSure from 'common/Dialogs/AreYouSure';
import { useToasts } from 'common/ActionToast';

import ThemeProvider from './ThemeStyle/ThemeProvider';
import HotspotPreview from './components/HotspotPreview';
import Header from './components/Header';
import HotspotLayer from './components/HotspotLayer';
import Hotspot from './components/Hotspot';
import CreateFlowForm from './components/CreateFlowForm';
import HotspotForm from './components/HotspotForm';
import Sidebar from './components/Sidebar';
import StepForm from './components/StepForm';
import IntroForm from './components/IntroForm';
import TourStatus, { TOUR_STATUS } from './components/TourStatus';
import FlowInput from './components/FlowInput';
import StepsInput from './components/StepsInput';
import EditorPlaceholder from './components/EditorPlaceholder';
import useTourState from './useTourState';
import useLoadMarvelScreens from './useLoadMarvelScreens';
import useRelativeToParentConversions from './useRelativeToParentConversions';

import Texts from './texts';
import { HOTSPOT_EVENTS, MODALS } from './constants';
import './styles.scss';

const MAX_EMBEDS_PER_SCREEN = 2;

function ExperienceEditorView() {
  const history = useHistory();
  const query = useQueryString();
  const { id: demoId } = useParams();
  const toasts = useToasts();

  const tourId = parseInt(query.get('tour_id'), 10);
  const tourType = query.get('type');
  const productType = query.get('product_type');
  const [screenLoaded, setScreenLoaded] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(TOUR_STATUS.SUCCESS);
  const tourState = useTourState(
    {
      demoId,
      tourId,
      productType,
      type: tourType,
    },
    {
      onSave: () => {
        setUpdateStatus(TOUR_STATUS.SUCCESS);
      },
      onSaveFailure: () => {
        setUpdateStatus(TOUR_STATUS.ERROR);
        toasts.onAdd(
          new Date().toString(),
          Texts.errorTitle,
          Texts.errorDescription,
        );
      },
    },
  );

  const screens = useLoadMarvelScreens(demoId);
  const modalState = useModalState([
    MODALS.CONCLUSION_MODAL,
    MODALS.INTRO_MODAL,
    MODALS.STEP_MODAL,
    MODALS.HOTSPOT_MODAL,
    MODALS.CREATE_FLOW_MODAL,
    MODALS.DELETE_FLOW_CONFIRMATION,
    MODALS.DELETE_HOTSPOT_CONFIRMATION,
    MODALS.DELETE_STEP_CONFIRMATION,
  ]);

  const hotspotContainerRef = useRef();
  const isMobile = productType === 'MOBILE';

  const conversions = useRelativeToParentConversions(
    hotspotContainerRef,
    {
      height: isMobile ? 65 : 100,
      width: isMobile ? 45 : 100,
    },
    tourState.changeHotspot,
  );

  const currentStepHotspots = tourState.getCurrentStepHotspots() || [];

  useWindowResize(() => {
    currentStepHotspots.forEach((hotspot) => tourState.changeHotspot(hotspot));
  }, [currentStepHotspots]);

  const onStepChange = (step) => {
    const currentId = get(tourState.tour.getCurrentStep(), 'id');
    if (currentId !== step.id) {
      setScreenLoaded(false);
      tourState.changeHeadByStep(step);
    }
  };

  const onFlowChange = (flowForm, meta) => {
    setScreenLoaded(false);
    tourState.changeFlow(flowForm, meta);
  };

  const onImageLoaded = () => {
    setTimeout(() => {
      setScreenLoaded(true);
    }, 250);
  };

  // move this logic inside Header and pass routes as params
  const handleGoBack = () => {
    history.push(routerMap.demo.getPath(demoId));
  };
  const handlePlayTour = () => {
    window.open(routerMap.playTour.getPath(tourType, tourId, productType));
  };

  // move to outside of function
  const showHotspotLayer = get(tourState.tour.getCurrentFlow(), 'stepIds', [])
    .length;
  const showStepPlaceholder =
    get(tourState.tour.getCurrentFlow(), 'id') &&
    !get(tourState.tour.getCurrentFlow(), 'stepIds', []).length;
  const showFlowPlaceholder =
    !get(tourState.tour.getCurrentFlow(), 'id') &&
    !get(tourState.tour.getFlowIds(), 'length');

  const handleDeleteCurrentFlow = () => {
    tourState.deleteCurrentFlow();
    modalState.closeModal();
  };

  // wrap
  const [preview, setPreview] = useState(true);
  const togglePreview = () => {
    setPreview(!preview);
  };

  const hotspotAdded = currentStepHotspots.find(
    (hotspot) => hotspot.event !== HOTSPOT_EVENTS.EMBED,
  );

  const maxEmbedsAdded =
    currentStepHotspots.filter(
      (hotspot) => hotspot.event === HOTSPOT_EVENTS.EMBED,
    ).length >= MAX_EMBEDS_PER_SCREEN;

  return (
    <ThemeProvider
      load={!tourState.isEmpty}
      onChange={tourState.changeFormat}
      initial={tourState.tour.getFormat()}
    >
      <div className="tour-editor-main-wrapper">
        {toasts.elements}
        {modalState.status === MODALS.DELETE_FLOW_CONFIRMATION && (
          <AreYouSure
            message={Texts.deleteFlowDescription}
            onAccept={handleDeleteCurrentFlow}
            onCancel={modalState.closeModal}
            show={modalState.open[MODALS.STEP_MODAL]}
            title={Texts.deleteFlowTitle}
          />
        )}
        {modalState.status === MODALS.DELETE_STEP_CONFIRMATION && (
          <AreYouSure
            closeOnAccept
            message={Texts.deleteStepDescription}
            onAccept={tourState.deleteStep}
            onCancel={modalState.closeModal}
            show={modalState.open[MODALS.DELETE_STEP_CONFIRMATION]}
            title={Texts.deleteStepTitle}
            value={modalState.meta}
          />
        )}
        <div id="tour-editor-bg" />
        <div>
          <div className="tour-sidebar">
            {!tourState.isEmpty && (
              <Sidebar
                title={Texts.tourLabel}
                flowInput={
                  <FlowInput
                    current={tourState.tour.getCurrentFlow()}
                    flowOptions={tourState.flowOptions}
                    onEditFlow={tourState.editCurrentFlowName}
                    onSelectedFlow={onFlowChange}
                    onDeleteFlow={
                      modalState.open[MODALS.DELETE_FLOW_CONFIRMATION]
                    }
                  />
                }
                stepsInput={
                  <StepsInput
                    allowNewEmbeds={!maxEmbedsAdded}
                    tour={tourState.tour}
                    onAddStep={modalState.open[MODALS.STEP_MODAL]}
                    onToggleFlowArrowNavigation={
                      tourState.toggleCurrentFlowArrowNavigation
                    }
                    onEditConclusion={modalState.open[MODALS.CONCLUSION_MODAL]}
                    onEditIntro={modalState.open[MODALS.INTRO_MODAL]}
                    onSelectedStep={onStepChange}
                    onDeleteStep={
                      modalState.open[MODALS.DELETE_STEP_CONFIRMATION]
                    }
                    onRearrangeStep={tourState.rearrangeStep}
                    onAddHotspot={conversions.convertToPercentage}
                  />
                }
                statusComponent={
                  <TourStatus
                    className="mt-3 px-3"
                    status={updateStatus}
                    isSaving={tourState.isSaving}
                  />
                }
                previewModeComponent={
                  <Form.Check
                    type="switch"
                    id="preview-switch"
                    checked={preview}
                    onChange={togglePreview}
                    label=""
                  />
                }
              />
            )}
          </div>
          <div className="tour-content-wrapper">
            <div
              id="tour-editor-header"
              className="px-4 py-1 bg-dark border-bottom border-info"
            >
              <Header
                onBack={handleGoBack}
                onPlayTour={handlePlayTour}
                name={query.get('name')}
              />
            </div>
            {showHotspotLayer && (
              <div className="tour-screen-content pt-0">
                <div className="d-flex align-items-center justify-content-center edit-section-wrapper">
                  <div
                    className={cx(
                      'edit-section shadow',
                      isMobile && 'mobile-product',
                    )}
                  >
                    {screenLoaded && (
                      <HotspotLayer
                        allowNewHotspots={!hotspotAdded}
                        onAddHotspot={conversions.convertToPercentage}
                      >
                        {currentStepHotspots.map((hotspot) => (
                          <Hotspot
                            containerDimensions={hotspotContainerRef.current}
                            hotspot={conversions.convertToPixel(hotspot)}
                            onEditHotspot={
                              modalState.open[MODALS.HOTSPOT_MODAL]
                            }
                            onDeleteHotspot={
                              modalState.open[
                                MODALS.DELETE_HOTSPOT_CONFIRMATION
                              ]
                            }
                            onUpdate={conversions.convertToPercentage}
                            preview={
                              preview && (
                                <HotspotPreview
                                  {...conversions.convertToPixel(hotspot)}
                                  updateHotspot={tourState.mergeHotspot}
                                />
                              )
                            }
                          />
                        ))}
                      </HotspotLayer>
                    )}
                    <Image
                      fluid
                      onLoad={onImageLoaded}
                      ref={hotspotContainerRef}
                      key={get(tourState.tour.getCurrentStep(), 'id')}
                      src={get(tourState.tour.getCurrentStep(), 'imageUrl')}
                    />
                  </div>
                </div>
              </div>
            )}
            <EditorPlaceholder
              show={showFlowPlaceholder}
              onClick={modalState.open[MODALS.CREATE_FLOW_MODAL]}
              label={Texts.addFlowPlaceholder}
            />
            <EditorPlaceholder
              show={showStepPlaceholder}
              onClick={modalState.open[MODALS.STEP_MODAL]}
              label={Texts.addStepPlaceholder}
            />
          </div>
          {modalState.status === MODALS.STEP_MODAL && (
            <StepForm
              show={modalState.status === MODALS.STEP_MODAL}
              value={modalState.meta}
              onSuccess={tourState.changeStep}
              onCancel={modalState.closeModal}
              screenOptions={screens}
            />
          )}
          {modalState.status === MODALS.CREATE_FLOW_MODAL && (
            <CreateFlowForm
              show={modalState.status === MODALS.CREATE_FLOW_MODAL}
              onSuccess={tourState.changeFlow}
              onCancel={modalState.closeModal}
            />
          )}
          {modalState.status === MODALS.HOTSPOT_MODAL && (
            <HotspotForm
              show={modalState.status === MODALS.HOTSPOT_MODAL}
              value={modalState.meta}
              onSuccess={tourState.changeHotspotData}
              onCancel={modalState.closeModal}
            />
          )}
          {modalState.status === MODALS.DELETE_HOTSPOT_CONFIRMATION && (
            <AreYouSure
              closeOnAccept
              message={Texts.deleteHotspotDescription}
              onAccept={tourState.deleteHotspot}
              onCancel={modalState.closeModal}
              show={modalState.open[MODALS.DELETE_HOTSPOT_CONFIRMATION]}
              title={Texts.deleteHotspotTitle}
              value={modalState.meta}
            />
          )}
          <IntroForm
            value={modalState.meta}
            show={modalState.status === MODALS.INTRO_MODAL}
            onSuccess={tourState.changeFlow}
            onCancel={modalState.closeModal}
          />
          <IntroForm
            isConclusion
            show={modalState.status === MODALS.CONCLUSION_MODAL}
            value={modalState.meta}
            onSuccess={tourState.changeFlow}
            onCancel={modalState.closeModal}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ExperienceEditorView;
