import React from 'react';
import { ErrorMessage as BaseErrorMessage } from 'formik';

function ErrorMessage(props) {
  return (
    <BaseErrorMessage
      component="div"
      className="subtext text-danger"
      {...props}
    />
  );
}

export default ErrorMessage;
