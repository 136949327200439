import React, { useState } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Button from 'common/Buttons/Button';
import { move } from 'utils/arrays';
import { GeneratePresentationSteps } from 'app/Views/SlidesNavigation/constants';

import Texts from './texts';
import './styles.scss';

function GroupsOrder({ groups, next, updateGenData }) {
  const [groupsOrder, setGroupsOrder] = useState(groups);

  const handleGenerate = () => {
    const groupOrderIds = groupsOrder.map((group) => group.id);

    updateGenData({
      groupsOrder: groupOrderIds,
    });
    next(GeneratePresentationSteps.GENERATE);
  };

  const goBack = () => {
    next(GeneratePresentationSteps.SLIDE_SELECTION);
  };

  const handleDropEnd = (dropped) => {
    if (!dropped?.destination) {
      return;
    }

    const currentPosition = dropped.source.index;
    const newPosition = dropped.destination.index;
    const groupToMove = groupsOrder[currentPosition];

    const updatedGroups = move(
      groupsOrder,
      groupToMove,
      currentPosition,
      newPosition,
    );

    setGroupsOrder(updatedGroups);
  };

  return (
    <div>
      <h5 className="mb-1">{Texts.title}</h5>
      <div className="p-2">
        <DragDropContext onDragEnd={handleDropEnd}>
          <Droppable droppableId="groups-droppable" className="d-flex">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {groupsOrder.map((group, index) => (
                  <Draggable
                    key={group.id}
                    draggableId={`${group.id}`}
                    index={index}
                    isDragDisabled={groups.length <= 1}
                  >
                    {(providedDraggable) => (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        className="groups-order d-flex justify-content-between mw-75 mb-2 p-1"
                      >
                        <div>
                          <span className="section-name mb-0 mx-2 text-capitalize">
                            {`${index + 1}. ${group.section_name}`}
                          </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="d-flex justify-content-end">
        <Button size="md" variant="secondary" onClick={goBack} className="mr-2">
          {Texts.return}
        </Button>
        <Button variant="primary" onClick={handleGenerate}>
          {Texts.continue}
        </Button>
      </div>
    </div>
  );
}

GroupsOrder.propTypes = {
  groups: arrayOf(
    shape({
      id: number.isRequired,
      section_name: string.isRequired,
      slide_list: arrayOf(string).isRequired,
    }),
  ),
  next: func.isRequired,
  updateGenData: func.isRequired,
};

GroupsOrder.defaultProps = {
  groups: [],
};

export default GroupsOrder;
