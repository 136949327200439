import React from 'react';
import { Formik } from 'formik';
import { bool, func, string } from 'prop-types';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import useAsyncLoad from 'utils/useAsyncLoad';
import AuthModal from 'utils/Google/AuthModal';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import SubmitErrorMessage from 'common/Message/SubmitError';
import Select from 'common/Inputs/AsyncSelect';
import GetFolderPresentations from 'app/Services/Google/GetFolderPresentations';

import Texts from './texts';

const validationSchema = yup.object({
  template_file: yup
    .object()
    .shape({
      name: yup.string().required(Texts.requiredError(Texts.fileLabel)),
    })
    .required(Texts.requiredError(Texts.fileLabel)),
});
const INITIAL = { template_file: undefined };

function ImportForm({ onCancel, onSubmit, show, folderId }) {
  const searchTemplateFile = useAsyncLoad(GetFolderPresentations(folderId));

  return (
    <AuthModal onCancel={onCancel}>
      <Modal size="md" show={show} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{Texts.title}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={INITIAL}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, errors, isSubmitting, setFieldValue, values }) => {
            return (
              <Form noValidate>
                <>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Label htmlFor="template_file">
                        {Texts.fileLabel}
                      </Form.Label>
                      <Select
                        name="template_file"
                        placeholder={Texts.selectPlaceholder}
                        value={
                          values.template_file
                            ? {
                                value: values.template_file,
                                label: values.template_file.name,
                              }
                            : null
                        }
                        loadOptions={searchTemplateFile}
                        onChange={setFieldValue}
                      />
                      {errors.template_file && (
                        <div className="subtext text-danger">
                          {errors.template_file.name}
                        </div>
                      )}
                    </Form.Group>
                  </Modal.Body>
                  {!isSubmitting && errors.internalServerError && (
                    <SubmitErrorMessage>
                      {errors.internalServerError}
                    </SubmitErrorMessage>
                  )}
                  <Modal.Footer>
                    <AcceptCancelButton
                      isProcessing={isSubmitting}
                      acceptLabel={Texts.acceptLabel}
                      onCancel={onCancel}
                      onAccept={handleSubmit}
                    />
                  </Modal.Footer>
                </>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </AuthModal>
  );
}

ImportForm.propTypes = {
  folderId: string.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  show: bool,
};

ImportForm.defaultProps = {
  show: false,
};

export default ImportForm;
