const Texts = {
  dash: 'Dashboard',
  demoLibrary: 'Demo Library',
  interactive: 'Interactives',
  reports: 'Reports',
  gslides: 'GSlides',
  settings: 'Settings',
  title: 'Demo Library',
};

export default Texts;
