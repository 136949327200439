import React from 'react';
import { func, node, any } from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';

function PrimitiveButton({ children, onClick, value, ...rest }) {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(value);
  };

  return (
    <div
      {...rest}
      className={cx(
        get(rest, 'className'),
        get(rest, 'disabled') && 'disabled',
      )}
      onClick={get(rest, 'disabled') ? null : handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex="0"
    >
      {children}
    </div>
  );
}

PrimitiveButton.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
  value: any,
};

PrimitiveButton.defaultProps = {
  value: null,
};

export default PrimitiveButton;
