import React from 'react';
import { object, string } from 'prop-types';
import Button from '../Primitive';
import './styles.scss';

function IconButton({ Icon, message, ...rest }) {
  return (
    <Button {...rest}>
      <div className="text-white">
        {message} <Icon className="button-icon bg-primary text-white rounded" />
      </div>
    </Button>
  );
}

IconButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Icon: object.isRequired,
  message: string,
};

IconButton.defaultProps = {
  message: undefined,
};

export default IconButton;
