import React from 'react';
import { Formik } from 'formik';
import { number, string, bool, func, shape } from 'prop-types';
import { get } from 'lodash';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import AcceptCancelButton from 'common/Buttons/AcceptCancel';
import SubmitErrorMessage from 'common/Message/SubmitError';
import Texts from './texts';
import './style.scss';

const validationSchema = yup.object({
  name: yup.string().required(Texts.requiredError(Texts.interactiveNameLabel)),
  prototype_url: yup
    .string()
    .url(Texts.invalidUrl)
    .required(Texts.requiredError(Texts.prototypeLabel)),
});

function InteractiveForm({ onCancel, onSubmit, interactive, show }) {
  return (
    <Modal size="md" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {interactive.id
            ? Texts.editTitle(get(interactive, 'name'))
            : Texts.createTitle}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={interactive}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          errors,
          isSubmitting,
          touched,
          values,
        }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Form.Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>{Texts.interactiveNameLabel}</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        onChange={handleChange}
                        value={values.name}
                        isInvalid={touched.name && errors.name}
                      />
                      {touched.name && errors.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>{Texts.prototypeLabel}</Form.Label>
                      <Form.Control
                        name="prototype_url"
                        type="text"
                        onChange={handleChange}
                        value={values.prototype_url}
                        isInvalid={
                          touched.prototype_url && errors.prototype_url
                        }
                      />
                      {touched.prototype_url && errors.prototype_url && (
                        <Form.Control.Feedback type="invalid">
                          {errors.prototype_url}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Modal.Body>
              {!isSubmitting && errors.internalServerError && (
                <SubmitErrorMessage>
                  {errors.internalServerError}
                </SubmitErrorMessage>
              )}
              <Modal.Footer>
                <AcceptCancelButton
                  isProcessing={isSubmitting}
                  acceptLabel={
                    interactive.id ? Texts.editLabel : Texts.acceptLabel
                  }
                  onCancel={onCancel}
                  onAccept={handleSubmit}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

InteractiveForm.propTypes = {
  interactive: shape({
    id: number,
    name: string,
    prototype_url: string,
  }),
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  show: bool,
};

InteractiveForm.defaultProps = {
  show: false,
  interactive: {
    id: undefined,
    name: '',
    prototype_url: '',
  },
};

export default InteractiveForm;
